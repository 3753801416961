import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import ArticleTitle from 'components/ArticleTitle'
import LoadingOverlay from 'components/LoadingOverlay'
import StockChangesForm from 'components/StockChangesForm'
import { useParams, useNavigate } from 'react-router'
import { usePrevious } from 'lib/utils'

import {
	fetchArticle,
	postNewStockQuantity,
	setQuantity,
	fetchStock,
} from './duck'

import { showMessage } from 'features/MessageBox/duck'

const StockCorrection = () => {
	const dispatch = useDispatch()

	const { articleId, stockLocation, dotYear } = useParams()
	const navigate = useNavigate()

	const article = useSelector(state => state.stockCorrection.article)
	const newQuantity = useSelector(state => state.stockCorrection.newQuantity)
	const stockQuantity = useSelector(state => state.stockCorrection.stockQuantity)
	const loading = useSelector(state => state.stockCorrection.loading)
	const completed = useSelector(state => state.stockCorrection.completed)
	const error = useSelector(state => state.stockCorrection.error)

	const prevCompleted = usePrevious(completed)
	const prevError = usePrevious(error)

	const onGoBackClicked = () => {
		navigate(-1)
	}

	const onChange = (name, value) => {
		dispatch(setQuantity(value))
	}

	const onSubmit = (e) => {
		e.preventDefault()

		if (!newQuantity.trim().length) {
			return
		}

		if (article != null)
			dispatch(postNewStockQuantity(article.articleId, newQuantity, stockLocation, dotYear))
	}

	const showError = useCallback(() => {
		dispatch(showMessage('Error', 'Unable to perform correction'))
	}, [dispatch])


	const fetchArticleStock = useCallback(() => {
		dispatch(fetchStock(articleId, stockLocation, dotYear))
	}, [dispatch, articleId, stockLocation, dotYear])

	useEffect(() => {
		if (!prevCompleted && completed) fetchArticleStock()
		if (!prevError && error) showError()
	}, [completed, error, prevCompleted, prevError, fetchArticleStock, showError])

	
	useEffect(() => {
		dispatch(fetchArticle(articleId))
		dispatch(fetchStock(articleId, stockLocation, dotYear))
	}, [dispatch, articleId, stockLocation, dotYear])

	const loadingSpinner = loading ? <LoadingOverlay /> : ''

	return (
		<Container>
			<Row className="justify-content-center">
				<Col xs={12} sm={12}>
					<Row className="justify-content-center">
						<Card style={{ width: '32rem' }}>
							<Card.Header>Correct quantity</Card.Header>
							<Card.Body>
								{article && <ArticleTitle item={article} dotYear={dotYear} />}
							</Card.Body>
						</Card>
					</Row>
				</Col>
			</Row>

			<Row className="justify-content-center">
				<StockChangesForm
					onSubmit={onSubmit}
					onBack={onGoBackClicked}
					onChange={onChange}
					currentQuantity={stockQuantity?.quantityInStock || 0}
					currentlyInPicking={stockQuantity?.quantityReserved || 0}
					newQuantity={newQuantity}
					isStockCorrection={true}
				/>
			</Row>

			{loadingSpinner}
		</Container>
	)
}

export default StockCorrection
