import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Card, Form } from 'react-bootstrap'
import FloatingNumpad from 'components/FloatingNumpad'
import NumpadInput from 'components/NumpadInput/NumpadInput'
import If from 'components/If'
import classNames from 'classnames'
import pickingListRowStatus from 'enums/pickingListRowStatus'
import { isAccessory, usePrevious } from 'lib/utils'
import useLongPress from 'lib/hooks/useLongPress'
import { showMessage } from 'features/MessageBox/duck'
import PropTypes from 'prop-types'

import './style.css'

const PickingRow = ({
	item,
	pickedValue,
	totalValue,
	handlePicked,
	rowIndex,
	selected,
	onBundle,
	toBeBundled,
	isBundleTarget,
	bundleTarget,
	onChangeParcelAmount,
	pickingListContainAccessory,
	selectOrderOnBundleTarget,
	selectedBundleTargetOrderId,
	disabled
}) => {
	const prevPickedValue = usePrevious(pickedValue)
	const dispatch = useDispatch()
	const [pickingStatus, setPickingStatus] = React.useState(pickingListRowStatus.None)
	const [partiallyPickedQuantity, setPartiallyPickedQuantity] = React.useState('')
	const [showPartialPick, setShowPartialPick] = React.useState(false)
	const pickingStatusVariant = pickingStatus === pickingListRowStatus.Picked ? 'warning' : 'dark'
	const pickingStatusTitle = pickingStatus === pickingListRowStatus.Picked ? 'Unpick' : 'Pick'

	const orderIds = item?.orderIds?.map(id => id.orderId)

	const defaultOptions = {
		shouldPreventDefault: true,
		delay: 500,
	}

	const items = [
		{
			key: 1,
			condition: true,
			label: 'ArticleId:',
			value: item.articleId,
		},
		{
			key: 2,
			condition: true,
			label: 'OrderIds',
			value: orderIds?.join(', ')
		},
		{
			key: 3,
			condition: true,
			label: 'Supplier No:',
			value: item.supplierArticleId,
		},
		{
			key: 4,
			condition: true,
			label: 'Stocklocation:',
			value: item.stockLocation,
		},
		{
			key: 5,
			condition: true,
			label: 'Total stock quantity:',
			value: item.totalStockQuantity,
		},
		{
			key: 6,
			condition: true,
			label: 'Quantity:',
			value: item.quantity,
		},
		{
			key: 7,
			condition: (isAccessory(item.mainGroup) || item.mainGroup === 210) && item.orderCount > 1,
			label: 'Observe:',
			value: `Packing ${item.quantitySeparated} `,
			info: true,
		},
		{
			key: 8,
			condition: item.bundle,
			label: 'Bundle:',
			value: 'Pack with above',
			info: true,
		},
		{
			key: 9,
			condition: item.orderedProgram,
			label: 'Info:',
			value: 'Programming ordered',
			info: true,
		},
		{
			key: 10,
			condition: item.orderedStudded,
			label: 'Info:',
			value: 'Ordered studded',
			info: true,
		},
		{
			key: 11,
			condition: item.studdingNeeded,
			label: 'Info:',
			value: 'Studding needed',
			info: true,
		},
		{
			key: 12,
			condition: isBundleTarget,
			label: 'Bundle',
			value: 'Bundle target',
			info: true,
		},
		{
			key: 13,
			condition: toBeBundled,
			label: 'Bundle',
			value: 'Bundle to target',
			info: true,
		}
	]

	const onChangeNumpadValue = (value) => {
		setPartiallyPickedQuantity(value)
	}

	const togglePartiallyPicked = () => {
		setShowPartialPick(!showPartialPick)
	}

	const handleOnSendMissingQuantity = (e) => {
		e.preventDefault()
		setShowPartialPick(!showPartialPick)

		if (partiallyPickedQuantity === '')
			return

		handlePicked(pickingListRowStatus.NotFound, rowIndex, partiallyPickedQuantity)
	}

	const onClearMissingQuantity = () => {
		setPartiallyPickedQuantity('')
	}

	const toogleFound = () => {
		if (!pickingStatus) {
			handlePicked(pickingListRowStatus.Picked, rowIndex)
		} else {
			handlePicked(pickingListRowStatus.None, rowIndex)
		}
		setPickingStatus(pickingStatus === pickingListRowStatus.Picked ? pickingListRowStatus.None : pickingListRowStatus.Picked)
	}

	const onLongPress = () => {

		if (item.bundle || !pickingListContainAccessory) {
			return
		}

		if (bundleTarget && bundleTarget?.orderIds?.length > 1 && !selectedBundleTargetOrderId && !isBundleTarget) {
			dispatch(showMessage('Alert', 'Target contains multiple orders, please select which order to use in target dropdown.'))
			return
		}

		let canBeBundled = true // Allow bundling if no bundleTarget has been set yet

		if (bundleTarget) {
			canBeBundled = item.orderIds.some(orderIdPair =>
				bundleTarget.orderIds.some(bundleOrderIdPair => bundleOrderIdPair.orderId === orderIdPair.orderId) && isAccessory(item.mainGroupId)
			)
		}

		if (canBeBundled || isBundleTarget) {
			onBundle(rowIndex, item.articleId, item.stockLocation, item.rowIds, item.orderIds, '')
		}
	}

	const onChangeHandler = (value) => {
		onChangeParcelAmount(value)
	}

	useEffect(() => {
		if (prevPickedValue !== pickedValue && totalValue >= 8) {
			handlePicked(pickingListRowStatus.Picked, rowIndex)
		}
	}, [pickedValue, totalValue, handlePicked, rowIndex, prevPickedValue])

	useEffect(() => {
		if (
			prevPickedValue !== pickedValue &&
			pickedValue === totalValue &&
			totalValue <= 8
		) {
			handlePicked(pickingListRowStatus.Picked, rowIndex)
		}
	}, [pickedValue, totalValue, handlePicked, rowIndex, prevPickedValue])

	useEffect(() => {
		if (pickedValue === totalValue) {
			setPickingStatus(pickingListRowStatus.Picked)
		}
	}, [pickedValue, totalValue])

	useEffect(() => {
		if (item.pickingStatus !== pickingStatus) {
			handlePicked(pickingStatus, rowIndex)
		}
	}, [item.pickingStatus, pickingStatus, handlePicked, rowIndex])

	const longPressEvent = useLongPress(onLongPress, defaultOptions)

	return (
		<div className="pickingrow">
			<Card.Body
				className={classNames({
					notFound: item.pickingStatus === pickingListRowStatus.NotFound,
					picked: item.pickingStatus === pickingListRowStatus.Picked,
					selected: selected,
					toBeBundled: toBeBundled,
					bundleTarget: isBundleTarget,
				})}
			>
				<div className="infodesc">{item.shortArticleText || item.articleText}</div>

				{items.map(
					(item, index) =>
						item.condition && (
							<div
								{...longPressEvent}
								key={item.key}
								className={`item row ${item.info ? 'highlight' : ''}`}
							>
								{item.label}
								<div className="info">{item.value}</div>

							</div>
						),
				)}

				<If condition={!isAccessory(item.mainGroupId) && isBundleTarget}>
					<div className="parcelAmount">
						<NumpadInput
							name="parcelAmount"
							type="number"
							value={bundleTarget?.parcelAmount}
							size="xxl"
							className="editable"
							onChange={(_, value) => onChangeHandler(value)}
							label='Change label quantity'
							readOnly
						/>
					</div>
				</If>

				{isBundleTarget && bundleTarget?.orderIds?.length > 1 && (
					<Form.Group controlId="Supplier">
						<Form.Control
							name="selectOrderId"
							value={selectedBundleTargetOrderId}
							onChange={(e) => selectOrderOnBundleTarget(e.target.value)}
							as="select"
							size="lg"
						>
							<option value="">- Select order -</option>
							{bundleTarget.orderIds.map((orderIdPair) => (
								<option key={orderIdPair.orderId} value={orderIdPair.orderId}>
									Order ID: {orderIdPair.orderId}
								</option>
							))}
						</Form.Control>
					</Form.Group>
				)}

				<div className={'pickingBt row'}>
					<Button variant={pickingStatusVariant} onClick={toogleFound} value={pickingStatus}>
						{pickingStatusTitle}: {pickedValue && pickedValue < totalValue && pickingStatus !== pickingListRowStatus.Picked ? pickedValue + '/' : ''}{totalValue} pcs
					</Button>
				</div>
				<div className="pickingBt row">
					<Button
						variant={'info'}
						onClick={togglePartiallyPicked}
						disabled={pickedValue === totalValue || pickingStatus}
					>
						Partly picked
					</Button>
				</div>
			</Card.Body>
			<If condition={showPartialPick}>
				<div>
					<FloatingNumpad
						headerText={'Enter amount picked'}
						closeOutSide={true}
						text={partiallyPickedQuantity}
						onChange={onChangeNumpadValue}
						onClose={togglePartiallyPicked}
						onSend={handleOnSendMissingQuantity}
						hideExtraButton={true}
						onClear={onClearMissingQuantity}
						showStockButton={false}
					/>
				</div>
			</If>
		</div >
	)
}

PickingRow.propTypes = {
	item: PropTypes.object,
	pickedValue: PropTypes.number,
	totalValue: PropTypes.number,
	handlePicked: PropTypes.func,
	rowIndex: PropTypes.number,
	selected: PropTypes.bool,
	isBundleTarget: PropTypes.bool,
	bundleTarget: PropTypes.object,
	onChangeParcelAmount: PropTypes.func,
	onBundle: PropTypes.func,
	toBeBundled: PropTypes.bool,
	disabled: PropTypes.any,
	selectOrderOnBundleTarget: PropTypes.func.isRequired,
	selectedBundleTargetOrderId: PropTypes.any,
	pickingListContainAccessory: PropTypes.bool.isRequired,
}

export default PickingRow
