const initialState = {
	showScanner: false,
}

const TOGGLE_SHOW_SCANNER = 'features/BarCodeScanner/TOGGLE_SHOW_SCANNER'

export const toggleShowScanner = () => {
	return {
		type: TOGGLE_SHOW_SCANNER
	}
}

const reducer = (state = initialState, { type, data }) => {
	switch (type) {
		case TOGGLE_SHOW_SCANNER:
			return {
				...state,
				showScanner: !state.showScanner,
			}
		default:
			return state
	}
}
export default reducer
