import './style.css'

import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FloatingWindow from 'components/FloatingWindow'
import LoadingOverlay from 'components/LoadingOverlay'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import StockAddForm from 'components/StockAddForm'
import { usePrevious } from 'lib/utils'
import propTypes from 'prop-types'

import { postAddNewStock, fetchArticle, setValue } from './duck'

import { showMessage } from 'features/MessageBox/duck'

const ArticleStockLocationAdd = ({ onClose, articleId, newLocation = '' }) => {
	const dispatch = useDispatch()
	const loading = useSelector((state) => state.articleStockLocationAdd.loading)
	const error = useSelector((state) => state.articleStockLocationAdd.error)
	const prevError = usePrevious(error)
	const completed = useSelector(
		(state) => state.articleStockLocationAdd.completed,
	)

	const title = useSelector((state) => state.articleStockLocationAdd.title)
	const articleIdValue = useSelector(
		(state) => state.articleStockLocationAdd.articleId,
	)
	const article = useSelector((state) => state.articleStockLocationAdd.article)
	const newQuantity = useSelector(
		(state) => state.articleStockLocationAdd.newQuantity,
	)
	const stockLocation = useSelector(
		(state) => state.articleStockLocationAdd.stockLocation,
	)
	const dotYear = useSelector((state) => state.articleStockLocationAdd.dotYear)
	const user = useSelector((state) => state.login.user)

	const [lockArticle, setLockArticle] = useState(false)
	const [lockLocation, setLockLocation] = useState(false)

	const loadingSpinner = loading ? <LoadingOverlay /> : ''

	const loadArticle = useCallback(() => {
		if (articleIdValue !== undefined && articleIdValue !== '') {
			dispatch(fetchArticle(articleIdValue))
		}
	}, [articleIdValue, dispatch])

	const onChange = (name, value) => {
		dispatch(setValue(name, value))
	}

	const onSubmit = (e) => {
		if (e) e.preventDefault()

		dispatch(
			postAddNewStock(
				article.articleId,
				stockLocation,
				newQuantity,
				user.username,
				dotYear,
			),
		)
	}

	const showError = useCallback(() => {
		dispatch(showMessage('Error', 'Failed to add article to stock location'))
	}, [dispatch])

	useEffect(() => {
		if (articleId) {
			dispatch(setValue('articleId', articleId))
			setLockArticle(true)
			loadArticle()
		}
	}, [dispatch, loadArticle, articleId])

	useEffect(() => {
		loadArticle()
	}, [articleIdValue, loadArticle])

	useEffect(() => {
		if (newLocation !== '') {
			dispatch(setValue('stockLocation', newLocation))
			setLockLocation(true)
		}
	}, [dispatch, newLocation])

	useEffect(() => {
		if (!prevError && error) {
			showError()
		}
	}, [error, prevError, showError])

	useEffect(() => {
		if (completed) {
			dispatch(showMessage('Success', 'Data is successfully updated'))
			onClose()
		}
	}, [completed, dispatch, onClose])

	return (
		<FloatingWindow
			title={title}
			showHeader={true}
			draggable={false}
			closeOutSide={true}
			onClose={onClose}
		>
			<Container>
				<Row className="justify-content-center">
					<StockAddForm
						onSubmit={onSubmit}
						onBack={onClose}
						onChange={onChange}
						articleId={articleIdValue}
						article={article}
						lockArticle={lockArticle}
						newQuantity={newQuantity}
						stockLocation={stockLocation}
						lockLocation={lockLocation}
						dotYear={dotYear}
					/>
				</Row>
				{loadingSpinner}
			</Container>
		</FloatingWindow>
	)
}

ArticleStockLocationAdd.propTypes = {
	articleId: propTypes.number,
	newLocation: propTypes.string,
	onClose: propTypes.func,
}

export default ArticleStockLocationAdd
