import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import PropTypes from 'prop-types'

const PrivateRoute = ({
	authenticated: isAuthenticated,
	initialCheck: isChecked,
	...rest
}) => {
	if (!isChecked)
		return null

	if (!isAuthenticated)
		return <Navigate to='/login' replace={true} />

	return <Outlet />
}

PrivateRoute.propTypes = {
	authenticated: PropTypes.bool,
	initialCheck: PropTypes.bool,
}

export default PrivateRoute
