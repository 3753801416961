const initialState = {
	loading: false,
	completed: false,
	article: null,
	moveQuantity: '',
	newLocation: '',
	stockInfo: null,
	stockLocationValid: null,
	error: false,
}
export default initialState
