import { store } from '../redux/store'
import * as Sentry from '@sentry/react'

class StompConnector {
	constructor(url, username, password) {
		this.url = url
		this.username = username
		this.password = password
		this.socket = null
		this.subscriptions = {}
		this.authenticated = false
		this.connected = false
		this.onConnectedListeners = []

		this.reconnectInterval = 10000
		this.maxReconnectAttempts = 20
		this.reconnectAttempts = 0
	}

	connect() {
		this.socket = new WebSocket(`wss://${this.url}`, 'stomp')
		this.socket.onopen = this.onOpen.bind(this)
		this.socket.onmessage = this.onMessage.bind(this)
		this.socket.onerror = this.onError.bind(this)
		this.socket.onclose = this.onClose.bind(this)
	}

	onOpen() {
		// eslint-disable-next-line
		console.log('Connected to STOMP server')
		this.authenticate()
		this.reconnectAttempts = 0
		this.stopReconnectTimer()
	}

	authenticate() {
		if (this.username && this.password) {
			const connectFrame = `CONNECT\naccept-version:1.2\nhost:/\nlogin:${this.username}\npasscode:${this.password}\n\n\0`
			const messageSent = this.sendMessage(connectFrame)

			if (!messageSent) {
				// eslint-disable-next-line no-console
				console.log('Failed to send authentication message, trying again...')

				setTimeout(() => {
					this.authenticate()
				}, 5000)
			}
		}
	}

	onMessage(event) {
		const message = this.parseFrame(event.data)

		// Handling different types of STOMP frames
		switch (message.command) {
			case 'CONNECTED':
				// eslint-disable-next-line
				console.log('Authentication successful')
				this.authenticated = true
				this.onConnectedListeners.forEach((callback) => callback())
				break
			case 'ERROR':
				// eslint-disable-next-line
				console.error('STOMP Error:', message.headers['message'])
				break
			case 'MESSAGE':
				this.handleSubscriptionMessage(message)
				break
			default:
				// eslint-disable-next-line
				console.log('Received unhandled message type:', message.command)
		}
	}

	handleSubscriptionMessage(message) {
		const { destination } = message.headers

		for (const subscribedEvent in this.subscriptions) {
			const regexPattern = new RegExp(
				'^' + subscribedEvent.replace(/\./g, '\\.').replace(/>/g, '.*') + '$',
			)
			if (regexPattern.test(destination)) {
				this.subscriptions[subscribedEvent](message.body)
				return // Exit the loop after finding and invoking the matching callback
			}
		}
		// eslint-disable-next-line
		console.log('No matching subscription found for destination:', destination)
	}

	parseFrame(frame) {
		const lines = frame.split('\n')
		let headers = {}
		let body = ''
		let command = lines[0]
		let readingHeaders = true
		for (let i = 1; i < lines.length; i++) {
			if (lines[i] === '' && readingHeaders) {
				readingHeaders = false
				continue
			}
			if (readingHeaders) {
				let [key, value] = lines[i].split(':')
				headers[key] = value
			} else {
				body += lines[i] + '\n'
			}
		}
		return { command, headers, body }
	}

	onError(event) {
		console.error('WebSocket Error:', event)
	}

	onClose() {
		// eslint-disable-next-line
		console.log('Disconnected from STOMP server')
		this.authenticated = false
		const loggedIn = store.getState().login.loggedIn

		if (loggedIn)
			this.tryReconnect()
	}

	tryReconnect() {
		if (this.reconnectAttempts < this.maxReconnectAttempts) {
			// eslint-disable-next-line
			console.log(`Reconnecting in ${this.reconnectInterval / 1000} seconds...`)
			this.reconnectTimer = setTimeout(() => {
				this.reconnectAttempts++
				this.connect()
			}, this.reconnectInterval)
		} else {
			this.stopReconnectTimer()
			console.error('Max reconnect attempts reached. Cannot reconnect.')
		}
	}

	stopReconnectTimer() {
		if (this.reconnectTimer) {
			clearTimeout(this.reconnectTimer)
		}
	}

	subscribe(destination, callback, type) {
		const combinedDestination = `/${type}/${destination}`
		this.subscriptions[combinedDestination] = callback
		const subscribeFrame = `SUBSCRIBE\nid:sub-${Object.keys(this.subscriptions).length}\ndestination:${combinedDestination}\n\n\0`
		return this.sendMessage(subscribeFrame)
	}

	subscribeToTopic(destination, callback) {
		return this.subscribe(destination, callback, 'topic')
	}

	subscribeToQueue(destination, callback) {
		return this.subscribe(destination, callback, 'queue')
	}

	sendToTopic(topic, message) {
		const sendFrame = `SEND\ndestination:/topic/${topic}\n\n${message}\0`
		return this.sendMessage(sendFrame)
	}

	sendToQueue(queue, message) {
		const sendFrame = `SEND\ndestination:/queue/${queue}\n\n${message}\0`
		return this.sendMessage(sendFrame)
	}

	sendMessage(message) {
		if (this.socket.readyState !== WebSocket.OPEN) {
			console.error('Cannot send message, not connected to server.')
			return false
		}

		try {
			this.socket.send(message)
		} catch (error) {
			Sentry.captureException(error)
			return false
		} finally {
			return true
		}
	}

	disconnect() {
		if (this.socket.readyState !== WebSocket.OPEN)
			return

		const disconnectFrame = 'DISCONNECT\n\n\0'
		const messageSent = this.sendMessage(disconnectFrame)
		this.stopReconnectTimer()

		if (messageSent)
			this.socket.close()
	}

	onConnected(callback) {
		this.onConnectedListeners.push(callback)
	}
}

export default StompConnector
