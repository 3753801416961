import './style.css'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import { Button } from 'react-bootstrap'
import ArticleTitle from 'components/ArticleTitle'
import ArticleIdInfo from 'components/ArticleIdInfo'
import FloatingButtonWrapper from 'components/FloatingButtonWrapper'
import ArticleLocationInfo from 'components/ArticleLocationInfo'
import barcodes from '../ArticleSearch/assets/barcodes.svg'
import BarCodeScanner from 'features/BarCodeScanner'
import { toggleShowScanner } from 'features/BarCodeScanner/duck'
import { useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'
import useStockTakingCreator from 'lib/hooks/useStockTakingCreator'
import StcokLocationMove from '../StockLocationMove'
import If from 'components/If'

const ArticleList = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const showScanner = useSelector((state) => state.barCodeScanner.showScanner)
	const locationSearch = useSelector((state) => state.stockLocationSearch)
	const articleSearch = useSelector((state) => state.articleSearch)
	const { loadingStocking, createStockTakingByArticleIds } = useStockTakingCreator()

	const [bulkRelocation, setBulkRelocation] = React.useState(null)
	const inStockTaking = bulkRelocation?.some((article) => article.moveStatus === 1)
	const [moveLocation, setMoveLocation] = React.useState(false)

	const onMoveLocation = () => {
		setMoveLocation(true)
	}

	const { searchType } = useParams()

	const checkEmptyResult = () => {
		if (locationSearch.articles === null && articleSearch.articles === null) {
			navigate(-1)
		}
	}

	const onNewScanResult = (articleId) => {
		const article = search.articles.find(
			(item) => item.articleId === Number(articleId),
		)
		onArticleClicked(article)
	}

	const onArticleClicked = (article) => {

		if (article) {
			if (searchType === 'article')
				navigate(`/stocklocations/${article.articleId}`)
			else {
				const location = locationSearch.location
				navigate(`/stocklocations/${article.articleId}/${location}`)
			}
		}
	}

	const onBulkLocationChange = (e) => {
		const { articles } = search

		if (Array.isArray(articles)) {
			const articlesWithMoveStatus = articles.map((article) => ({
				...article,
				moveStatus: 1,
			}))
			setBulkRelocation(articlesWithMoveStatus)
		}
	}

	const onArticleClickBulkRelocation = (article) => {
		const updatedArticles = bulkRelocation.map((item) => {
			if (item.articleId === article.articleId) {
				return {
					...item,
					moveStatus: item.moveStatus === 1 ? 0 : 1,
				}
			}
			return item
		})
		setBulkRelocation(updatedArticles)
	}

	const onCancelStockTaking = () => {
		const { articles } = search
		if (Array.isArray(articles)) {
			const articlesWithMoveStatus = articles.map((article) => ({
				...article,
				moveStatus: 0,
			}))
			setBulkRelocation(articlesWithMoveStatus)
		}
	}

	const onCreateStockTakingList = () => {
		const selectedArticleIds = bulkRelocation
			.filter((article) => article.moveStatus === 1)
			.map((article) => article.articleId)

		createStockTakingByArticleIds(selectedArticleIds, locationSearch.location)
	}

	const toggleScanner = () => {
		dispatch(toggleShowScanner())
	}

	useEffect(() => {
		checkEmptyResult()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	let search
	if (searchType === 'article') search = articleSearch
	else search = locationSearch

	return (
		<>
			<Container className="articleList">
				{
					<Row className="justify-content-center">
						<Col xs={12} sm={12}>
							<Row className="justify-content-center">
								{search?.articles
									? search.articles.map((item) => (
										<Card
											key={item.articleId}
											onClick={() => {
												const isAnyArticlePicked = bulkRelocation?.some(
													(article) => article.moveStatus === 1,
												)
												if (!isAnyArticlePicked) {
													onArticleClicked(item)
												} else {
													onArticleClickBulkRelocation(item)
												}
											}}
										>
											<Card.Body
												className={classNames({
													notFound:
														bulkRelocation?.find(
															(article) =>
																article.articleId === item.articleId,
														)?.moveStatus === 0,
													picked:
														bulkRelocation?.find(
															(article) =>
																article.articleId === item.articleId,
														)?.moveStatus === 1,
												})}
											>
												<ArticleTitle item={item} />
												<ArticleLocationInfo
													quantity={item.quantityInStock}
												/>
												<ArticleIdInfo
													articleId={item.articleId}
													supplierArticleId={item.supplierArticleId}
												/>
											</Card.Body>
										</Card>
									))
									: ''}
							</Row>
						</Col>
					</Row>
				}
				{showScanner && <BarCodeScanner onScannedResult={onNewScanResult} />}
			</Container>
			<FloatingButtonWrapper showHome={true}>
				{inStockTaking && (
					<Button
						variant="success"
						size="lg"
						onClick={onCreateStockTakingList}
						disabled={loadingStocking}
					>
						Create stocktaking
					</Button>
				)}
				{inStockTaking && (
					<Button
						variant="warning"
						size="lg"
						onClick={onCancelStockTaking}
						disabled={loadingStocking}
					>
						Cancel stocktaking
					</Button>
				)}
				{!inStockTaking && searchType === 'location' && (
					<Button variant="success" size="lg" onClick={onBulkLocationChange}>
						Stocktaking
					</Button>
				)}
				{!inStockTaking && searchType === 'location' && (
					<Button variant="warning" size="lg" onClick={onMoveLocation}>
						Move Location
					</Button>
				)}
				{search?.articles?.length > 1 && !inStockTaking && (
					<Button variant="secondary" size="lg" onClick={toggleScanner}>
						<img src={barcodes} alt="barcode input" />
					</Button>
				)}
			</FloatingButtonWrapper>

			<If condition={moveLocation}>
				<StcokLocationMove
					currentStockLocation={locationSearch.location}
					newStockLocation=''
				/>
			</If>
		</>
	)
}

export default ArticleList
