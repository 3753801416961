const initialState = {
	title: 'Add article to stock location',
	loading: false,
	completed: false,
	articleId: '',
	article: '',
	newQuantity: '',
	stockLocation: '',
	dotYear: '',
	error: false,
	showModal: true,
}

const POST_ADDNEWSTOCK = 'features/MoveArticle/POST_NEWSTOCKLOCATION'
const POST_ADDNEWSTOCK_SUCCESS =
	'features/MoveArticle/POST_NEWSTOCKLOCATION_SUCCESS'
const POST_ADDNEWSTOCK_FAILED =
	'features/MoveArticle/POST_NEWSTOCKLOCATION_FAILED'

const FETCH_ARTICLE = 'features/ArticleStockLocationAdd/FETCH_ARTICLE'
const FETCH_ARTICLE_SUCCESS =
	'features/ArticleStockLocationAdd/FETCH_ARTICLE_SUCCESS'
const FETCH_ARTICLE_FAILED =
	'features/ArticleStockLocationAdd/FETCH_ARTICLE_FAILED'
const SET_VALUE = 'features/ArticleStockLocationAdd/SET_VALUE'

export const postAddNewStock = (
	articleId,
	stockLocation,
	quantity,
	username,
	dotYear,
) => {
	const payload = {
		articleId,
		stockLocation,
		quantity,
		username,
		dotYear,
	}

	return {
		types: [
			POST_ADDNEWSTOCK,
			POST_ADDNEWSTOCK_SUCCESS,
			POST_ADDNEWSTOCK_FAILED,
		],
		callApi: {
			endpoint: 'api/StockLocationAddArticle/',
			method: 'POST',
			data: payload,
		},
	}
}

export const fetchArticle = (articleId) => {
	return {
		types: [FETCH_ARTICLE, FETCH_ARTICLE_SUCCESS, FETCH_ARTICLE_FAILED],
		callApi: {
			endpoint: 'api/article/' + articleId,
			method: 'GET',
		},
	}
}

export const setValue = (name, value) => {
	return {
		type: SET_VALUE,
		name,
		value,
	}
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case POST_ADDNEWSTOCK:
			return {
				...state,
				completed: false,
				loading: true,
				error: false,
			}
		case POST_ADDNEWSTOCK_SUCCESS:
			return {
				...state,
				completed: true,
				article: '',
				newQuantity: '',
				stockLocation: '',
				dotYear: '',
				articleId: '',
				newLocation: '',
				loading: false,
				error: false,
			}
		case POST_ADDNEWSTOCK_FAILED:
			return {
				...state,
				loading: false,
				error: true,
			}
		case FETCH_ARTICLE:
			return {
				...state,
				loading: true,
				article: null,
			}
		case FETCH_ARTICLE_SUCCESS:
			return {
				...state,
				loading: false,
				article: action.data,
			}
		case FETCH_ARTICLE_FAILED:
			return {
				...state,
				loading: false,
				article: null,
			}
		case SET_VALUE:
			return {
				...state,
				[action.name]: action.value,
			}
		default:
			return state
	}
}
