import initialState from './initialState'

const POST_NEWSTOCKAMOUNT = 'features/StockCorrection/POST_NEWSTOCKAMOUNT'
const POST_NEWSTOCKAMOUNT_SUCCESS =
	'features/StockCorrection/FETCH_STOCKARTICLES_SUCCESS'
const POST_NEWSTOCKAMOUNT_FAILED =
	'features/StockCorrection/FETCH_STOCKARTICLES_FAILED'

const FETCH_ARTICLE = 'features/StockCorrection/FETCH_ARTICLE'
const FETCH_ARTICLE_SUCCESS = 'features/StockCorrection/FETCH_ARTICLE_SUCCESS'
const FETCH_ARTICLE_FAILED = 'features/StockCorrection/FETCH_ARTICLE_FAILED'

const FETCH_STOCK = 'features/StockCorrection/FETCH_STOCK'
const FETCH_STOCK_SUCCESS = 'features/StockCorrection/FETCH_STOCK_SUCCESS'
const FETCH_STOCK_FAILED = 'features/StockCorrection/FETCH_STOCK_FAILED'

const SET_QUANTITY = 'features/StockCorrection/SET_QUANTITY'

//Actions
export const fetchArticle = (articleId) => {
	return {
		types: [FETCH_ARTICLE, FETCH_ARTICLE_SUCCESS, FETCH_ARTICLE_FAILED],
		callApi: {
			endpoint: 'api/article/' + articleId,
			method: 'GET',
		},
	}
}

export const fetchStock = (articleId, stockLocation, dotYear) => {
	const payload = {
		articleId,
		stockLocation,
		dotYear,
	}

	return {
		types: [FETCH_STOCK, FETCH_STOCK_SUCCESS, FETCH_STOCK_FAILED],
		callApi: {
			data: payload,
			endpoint: 'api/stocklocation/',
			method: 'GET',
		},
	}
}

export const postNewStockQuantity = (
	articleId,
	quantity,
	stockLocation,
	dotYear,
) => {
	const payload = {
		articleId,
		quantity,
		stockLocation,
		dotYear,
	}

	return {
		types: [
			POST_NEWSTOCKAMOUNT,
			POST_NEWSTOCKAMOUNT_SUCCESS,
			POST_NEWSTOCKAMOUNT_FAILED,
		],
		callApi: {
			endpoint: 'api/StockCorrection/',
			method: 'POST',
			data: payload,
		},
	}
}

export const setQuantity = (value) => {
	return {
		type: SET_QUANTITY,
		value,
	}
}

//Reducer
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_ARTICLE:
			return {
				...state,
				loading: true,
				article: null,
			}
		case FETCH_ARTICLE_SUCCESS:
			return {
				...state,
				loading: false,
				article: action.data,
				newQuantity: '',
			}
		case FETCH_ARTICLE_FAILED:
			return {
				...state,
				loading: false,
				article: null,
			}
		case POST_NEWSTOCKAMOUNT:
			return {
				...state,
				completed: false,
				loading: true,
				error: false,
			}
		case POST_NEWSTOCKAMOUNT_SUCCESS:
			return {
				...state,
				completed: true,
				newQuantity: '',
				loading: false,
				error: false,
			}
		case POST_NEWSTOCKAMOUNT_FAILED:
			return {
				...state,
				loading: false,
				error: true,
			}
		case SET_QUANTITY:
			return {
				...state,
				newQuantity: action.value,
			}
		case FETCH_STOCK:
			return {
				...state,
				stockQuantity: null,
				completed: false,
				loading: true,
			}
		case FETCH_STOCK_SUCCESS:
			return {
				...state,
				stockQuantity: action.data,
				loading: false,
			}
		case FETCH_STOCK_FAILED:
			return {
				...state,
				stockQuantity: null,
				loading: false,
			}
		default:
			return state
	}
}
