import './style.css'
import React, {useCallback, useEffect, useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Button } from 'react-bootstrap'
import InboundInfo from 'components/InboundInfo'
import LoadingSpinner from 'components/LoadingSpinner'
import FloatingButtonWrapper from 'components/FloatingButtonWrapper'
import SupplierInfo from 'components/SupplierInfo'
import { useNavigate } from 'react-router-dom'
import FloatingFilterButton from 'components/FloatingFilterButton'
import If from 'components/If'
import InboundDeliverySearchForm from 'components/InboundDeliverySearchForm'
import FloatingWindow from 'components/FloatingWindow'
import {showMessage} from '../MessageBox/duck'

import { fetchInboundDeliverys, fetchSuppliers, setValue } from './duck'

const InboundDeliveryList = () => {
	const loading = useSelector((state) => state.inboundDeliverys.loading)
	const inboundDeliverys = useSelector((state) => state.inboundDeliverys.inboundDeliverys)
	const sort = useSelector((state) => state.inboundDeliverys.sort)
	const suppliers = useSelector((state) => state.inboundDeliverys.suppliers)
	const ean = useSelector((state) => state.inboundDeliverys.ean)
	const supplierArticleNumber = useSelector((state) => state.inboundDeliverys.supplierArticleNumber)
	const waybillNumber = useSelector((state) => state.inboundDeliverys.waybillNumber)
	const supplier = useSelector((state) => state.inboundDeliverys.supplier)
	const inboundDeliveryType = useSelector((state) => state.inboundDeliverys.inboundDeliveryType)
	const supplierOrderNumber = useSelector((state) => state.inboundDeliverys.supplierOrderNumber)

	const completed = useSelector((state) => state.inboundDeliverys.completed)
	const [filter, setFilter] = React.useState(false)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const submit = useRef(false)

	const sectionHeader = useRef('Inbound Deliveries')

	const onSubmit = (e) => {
		if (e) e.preventDefault()

		if (((ean.length > 0 && ean.length < 3) ||
				(supplierArticleNumber.length > 0 && supplierArticleNumber.length < 3) ||
				(supplierOrderNumber.length > 0 && supplierOrderNumber.length < 2) ||
				(waybillNumber.length > 0 && waybillNumber.length < 3)) && !supplier) {
			dispatch(showMessage('Error', 'Please enter at least 3 characters'))
		} else {
			submit.current = true
			dispatch(
				fetchInboundDeliverys(
					supplier,
					ean,
					supplierArticleNumber,
					waybillNumber,
					supplierOrderNumber,
					sort,
				),
			)
		}
	}

	const fetchList = () => {
		dispatch(fetchInboundDeliverys(supplier, ean, supplierArticleNumber, waybillNumber, supplierOrderNumber, sort))
	}

	const onClose = () => {
		navigate(-1)
	}

	const onChange = (name, value) => {
		dispatch(setValue(name, value))
	}

	const onFilterClicked = () => {
		submit.current = false
		onFilter()
	}

	const onDeliveryClicked = (item) => {
		navigate(`/inboundDelivery/${item.sequenceNumber}`)
	}

	const onFilter = useCallback(() => {
		setFilter(!filter)
	}, [filter, setFilter])

	const goHome = () => {
		navigate('/')
	}

	const hideInboundDeliveryRow = (deliveryType) => {
		switch (inboundDeliveryType) {
			case 'purchaseOrder':
				if (deliveryType !== 0) return 'hidden'
				break
			case 'customerReturn':
				if (deliveryType !== 1) return 'hidden'
				break
			default:
				return ''
		}
	}

	const loadingSpinner = loading ? <LoadingSpinner /> : ''

	useEffect(() => {
		fetchList()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if(completed && inboundDeliverys.length === 0) {
			dispatch(showMessage('Info', 'No inbound deliveries found'))
		}
	}, [completed, dispatch, inboundDeliverys])

	useEffect(() => {
		if(filter && completed && inboundDeliverys.length > 0 && submit.current) {
			onFilter()
		}
	}, [completed, inboundDeliverys, filter, onFilter])

	useEffect(() => {
		if(filter) {
			dispatch(fetchSuppliers())
		}
	}, [filter, dispatch])

	useEffect(() => {
		switch (inboundDeliveryType) {
			case 'purchaseOrder':
				sectionHeader.current = 'Purchase Orders'
				break
			case 'customerReturn':
				sectionHeader.current = 'Customer Returns'
				break
			default:
				sectionHeader.current = 'Inbound Deliveries'
		}
	}, [inboundDeliveryType])

	return (
		<>
			<Container className="inboundDeliverysList">
				{loadingSpinner}
				<Row className="justify-content-center">
					<Col xs={12} sm={12}>
						<h3>{sectionHeader.current}</h3>
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col xs={12} sm={12}>
						<Row className="justify-content-center">
							{inboundDeliverys?.length
								? inboundDeliverys.map((item) => (
									<Card
										key={item.sequenceNumber}
										onClick={() => {
											onDeliveryClicked(item)
										}}
										className={hideInboundDeliveryRow(item.deliveryType)}
									>
										<Card.Body>
											<InboundInfo item={item} />
											<SupplierInfo supplier={item.supplier} supplierOrderId={item.supplierOrderId} />
										</Card.Body>
									</Card>
								))
								: ''}
						</Row>
					</Col>
				</Row>
			</Container>

			<If condition={filter}>
				<FloatingWindow className="justify-content-center" closeOutSide={false} draggable={false} showHeader={true} fullSize={true}>
					<InboundDeliverySearchForm
						onSubmit={onSubmit}
						onBack={onClose}
						onChange={onChange}
						suppliers={suppliers}
						supplier={supplier}
						ean={ean}
						supplierArticleNumber={supplierArticleNumber}
						waybillNumber={waybillNumber}
						inboundDeliveryType={inboundDeliveryType}
						supplierOrderNumber={supplierOrderNumber}
					/>
				</FloatingWindow>
			</If>

			<FloatingButtonWrapper>
				<FloatingFilterButton
					positionX='middle'
					positionY='bottom'
					btnState={filter}
					onClick={onFilterClicked}
					title='Filter and Search'
				/>
				<Button variant="danger" size="lg" onClick={goHome}>
					Home
				</Button>
			</FloatingButtonWrapper>
		</>
	)
}

export default InboundDeliveryList
