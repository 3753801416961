import { apiBase } from './apiBase'

export const articleApi = apiBase.injectEndpoints({
	endpoints: (builder) => ({
		getArticle: builder.query({
			query: (articleId) => `article/${articleId}`,
			providesTags: (result, error, articleId) => [{ type: 'article', id: articleId }],
			//keepUnusedDataFor: 60
		}),
		// editArticle: builder.mutation({
		// 	query: (article) => ({
		// 		url: `article/${article.articleId}`,
		// 		method: 'PUT',
		// 		body: article,
		// 		invalidateTags: [{ type: 'article', id: article.articleId }],
		// 	}),
		// }),
	}),
})

export const { useGetArticleQuery, useLazyGetArticleQuery } = articleApi