import React, { useEffect, useCallback, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import LoadingSpinner from 'components/LoadingSpinner'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Button, Card, Container } from 'react-bootstrap'
import FloatingButtonWrapper from 'components/FloatingButtonWrapper'
import PickingRow from 'components/PickingRow'
import pickingListRowStatus from 'enums/pickingListRowStatus'
import BarCodeScanner from 'features/BarCodeScanner'
import barcode from '../ArticleSearch/assets/barcodes.svg'
import usePhysicalScanner from 'lib/hooks/usePhysicalScanner'
import ConfirmationWindow from 'components/ConfirmationWindow/ConfirmationWindow'
import usePrinters from 'lib/hooks/usePrinters'
import Printing from 'features/Printing/Printing'
import { toggleShowPrinting } from 'features/Printing/duck'
import SelectRowCorrection from './SelectRowCorrection'
import PickingListCreateForm from 'components/PickingListCreateForm'
import { toggleShowScanner } from 'features/BarCodeScanner/duck'
import { showMessage } from '../MessageBox/duck'
import { isAccessory } from 'lib/utils'

import './style.css'

import {
	fetchPickingList,
	startNewPickingList,
	fetchPickingFilter,
	updatePickingStatus,
	completePickingList,
	performPickingListRowCorrection,
	performPickingListRowDeleteCorrection,
	performPickingListRowPartialCorrection,
	setScannedArticles,
	bundleItems,
	resetErrorMessage,
} from './duck'

const Picking = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const loading = useSelector((state) => state.pickingList.loading)
	const showScanner = useSelector((state) => state.barCodeScanner.showScanner)
	const pickingList = useSelector((state) => state.pickingList.pickingList)
	const pickingListRows = useSelector((state) => state.pickingList.pickingList?.rows)
	const pickingGroups = useSelector((state) => state.pickingList.pickingGroups)
	const pickingBuildings = useSelector((state) => state.pickingList.pickingBuildings)
	const scannedArticles = useSelector((state) => state.pickingList.scannedArticles)
	const showPrinting = useSelector((state) => state.printing.showPrinting)
	const errorMessage = useSelector((state) => state.pickingList.errorMessage)

	const [selectedBuilding, setSelectedBuilding] = useState('')
	const [selectedFloor, setSelectedFloor] = useState('')
	const [selectedProductGroup, setSelectedProductGroup] = useState('')
	const [selectedAccessoryOption, setSelectedAccessoryOption] = useState('')

	const [selectedGroup, setSelectedGroup] = useState('')
	const [selectedLocation, setSelectedLocation] = useState(null)
	const [warnOnGoHome, setWarnOnGoHome] = useState(false)
	const [noAlternativeLocationError, setNoAlternativeLocationError] = useState(false)

	const [correctionData, setCorrectionData] = useState(null)

	const [bundleArticles, setBundleArticles] = useState([])
	const [bundleTarget, setBundleTarget] = useState(null)

	const onScannedArticleRef = useRef()

	const hasPickingList = pickingList?.rows?.length > 0
	const pickingListContainAccessory = pickingList?.rows?.some((item) => isAccessory(item.mainGroupId))
	const { printPickingList } = usePrinters()

	const goHome = () => {
		setWarnOnGoHome(false)
		dispatch(setScannedArticles({}))
		navigate('/')
	}

	const handleGoHome = () => {
		setWarnOnGoHome(true)
	}

	const startNewList = async () => {
		await dispatch(
			startNewPickingList({
				pickingGroupId: Number(selectedGroup),
				buildingOption: Number(selectedBuilding),
				floorOption: Number(selectedFloor),
				productGroup: Number(selectedProductGroup),
				includeAccessories: Number(selectedAccessoryOption)
			})
		)
		dispatch(fetchPickingList({ nextBusinessDay: false }))
	}

	const handleOnChangeParcelAmount = (value) => {
		setBundleTarget(prevBundleTarget => ({
			...prevBundleTarget,
			parcelAmount: value,
		}))
	}

	const onHandleSelectedBundleTargetOrder = (selectedOrderId) => {
		setBundleTarget({ ...bundleTarget, selectedOrderId: selectedOrderId })
	}

	const onHandleBundle = (rowIndex, articleId, stockLocation, rowIds, orderIds, parcelAmount, selectedOrderId) => {

		const newItem = { rowIndex, articleId, stockLocation, rowIds, orderIds, parcelAmount, selectedOrderId }

		if (!bundleTarget) {
			setBundleTarget(newItem)
			return
		}

		if (bundleTarget.rowIndex === rowIndex && bundleTarget.articleId === articleId && bundleTarget.stockLocation === stockLocation) {
			setBundleTarget(null)
			setBundleArticles([])
			return
		}

		// Check if the item already exists in bundleArticles
		const itemExists = bundleArticles?.some(
			(item) => item.rowIndex === rowIndex && item.articleId === articleId && item.stockLocation === stockLocation
		)

		if (!itemExists) {
			setBundleArticles((prevBundleArticles) => [...(prevBundleArticles || []), newItem])
		}
		else if (itemExists) {
			setBundleArticles((prevBundleArticles) => prevBundleArticles.filter(
				(item) => !(item.rowIndex === rowIndex && item.articleId === articleId && item.stockLocation === stockLocation && item.rowIds === rowIds && item.orderIds === orderIds)
			))
		}
	}

	const onChangePickingBuilding = (e) => {
		setSelectedBuilding(e.target.value)
	}

	const onChangePickingFloor = (e) => {
		setSelectedFloor(e.target.value)
	}

	const onChangePickingProductGroup = (e) => {
		setSelectedProductGroup(e.target.value)
	}

	const onChangePickingAccessory = (e) => {
		setSelectedAccessoryOption(e.target.value)
	}

	const onChangePickingGroup = (e) => {
		setSelectedGroup(e.target.value)
	}

	const handlePicked = async (value, rowIndex, partiallyPickedQuantity) => {
		const row = pickingList?.rows?.[rowIndex]

		if (value === 2 && row) {
			const { articleId, ean, stockLocation } = row

			const correctionData = {
				ArticleId: articleId,
				StockLocation: stockLocation,
				PickedQuantity: scannedArticles[stockLocation]?.[ean] || partiallyPickedQuantity,
				PickingGroupId: Number(selectedGroup),
				NextBusinessDay: false,
			}

			setCorrectionData(correctionData)

			await dispatch(performPickingListRowCorrection(correctionData))
			dispatch(fetchPickingList({ nextBusinessDay: false }))
		} else if (value === 0 && row) {
			const { ean, stockLocation } = row
			dispatch(setScannedArticles({ ...scannedArticles, [stockLocation]: { ...scannedArticles[stockLocation], [ean]: 0 } }))
			dispatch(updatePickingStatus(value, rowIndex))
		} else {
			dispatch(updatePickingStatus(value, rowIndex))
		}
	}

	const savePicking = () => {
		dispatch(completePickingList(pickingList))
		dispatch(fetchPickingFilter())
	}

	const updateScannedArticles = useCallback(
		(location, ean) => {
			const matchingItem = pickingListRows.find(
				(item) =>
					String(item.ean) === String(ean) &&
					String(item.stockLocation) === String(location) &&
					String(item.pickingStatus) === String(pickingListRowStatus.None),
			)

			// Calculate the new scanned quantity for the scanned article
			const newScannedQuantity =
				(scannedArticles[location] && scannedArticles[location][ean]) || 0

			// Check if the new scanned quantity doesn't exceed the picking row's quantity
			if (newScannedQuantity < matchingItem.quantity) {
				const updatedRows = pickingListRows.map((item) => {
					if (item.ean === ean && item.stockLocation === location) {
						return { ...item, quantity: item.quantity + 1 }
					}
					return item
				})

				dispatch(updatePickingStatus(updatedRows))

				const updatedScannedItem = {
					...scannedArticles[location],
					[ean]: newScannedQuantity + 1,
				}

				// Update scannedArticles for the specific location
				dispatch(
					setScannedArticles({
						...scannedArticles,
						[location]: updatedScannedItem,
					}),
				)
			} else {
				dispatch(
					showMessage('Scanned quantity exceeds the picking rows quantity.'),
				)
			}

			if ((newScannedQuantity + 1) === matchingItem.quantity) {
				setSelectedLocation(null)
			}

		},
		[dispatch, scannedArticles, pickingListRows, setSelectedLocation],
	)

	const onScannedArticle = useCallback(
		(value) => {

			const matchingLocation = pickingListRows.find(
				(item) => item.ean === parseFloat(value),
			)

			if (matchingLocation) {
				setSelectedLocation(matchingLocation.stockLocation)
				updateScannedArticles(matchingLocation.stockLocation, value)
			} else {
				dispatch(
					showMessage(`Location ${value} not found in the picking list.`),
				)
			}
		},
		[pickingListRows, dispatch, setSelectedLocation, updateScannedArticles],
	)

	const calculatePickedValue = (stockLocation) => {
		if (scannedArticles[stockLocation]) {
			const quantities = Object.values(scannedArticles[stockLocation])
			const totalPicked = quantities.reduce(
				(total, quantity) => total + quantity,
				0,
			)

			// Get the total quantity allowed for this location
			const totalQuantity =
				pickingList.rows.find((item) => item.stockLocation === stockLocation)
					?.quantity || 0

			// Ensure that the total picked quantity does not exceed the total quantity allowed
			return Math.min(totalPicked, totalQuantity)
		}

		return 0
	}

	const performBundling = () => {
		dispatch(bundleItems({ sequenceNumber: pickingList.sequenceNumber, target: bundleTarget, rows: bundleArticles }))
		setBundleArticles([])
		setBundleTarget(null)
	}

	const removeRowFromList = async () => {
		setNoAlternativeLocationError(false)
		dispatch(resetErrorMessage())
		await dispatch(performPickingListRowDeleteCorrection(correctionData))
		dispatch(fetchPickingList({ nextBusinessDay: false }))
	}

	const removeAmountFromList = async () => {
		setNoAlternativeLocationError(false)
		dispatch(resetErrorMessage())
		await dispatch(performPickingListRowPartialCorrection(correctionData))
		dispatch(fetchPickingList({ nextBusinessDay: false }))
	}

	const togglePrint = () => {

		if (checkIfAccessories()) {
			dispatch(showMessage('Reminder', 'Pickinglist contains accessories, any bundling or parcel quantity changes should be performed before printing!'))
		}

		dispatch(toggleShowPrinting())
	}

	const checkIfAccessories = () => {
		return pickingList?.rows?.some((item) => isAccessory(item.mainGroupId))
	}

	const onPrintPicking = (selectedPrinter) => {
		printPickingList(selectedPrinter, pickingList.sequenceNumber)
	}

	const toggleScanner = () => {
		dispatch(toggleShowScanner())
	}

	const onCloseLocationError = () => {
		setNoAlternativeLocationError(false)
	}

	const filterList = (bundleTarget, pickingList) => {
		if (!bundleTarget || !pickingList?.rows) {
			return pickingList?.rows || []
		}

		const updatedList = pickingList.rows
			.map((item, index) => ({
				...item,
				originalRowIndex: index,
			}))
			.filter((item) => {
				// Check if there is any overlapping OrderId between item and bundleTarget
				const hasCommonOrderId =
					item.orderIds?.some((orderIdPair) =>
						bundleTarget?.orderIds?.some(
							(bundleOrderIdPair) => bundleOrderIdPair.orderId === orderIdPair.orderId
						)
					) && !item.bundle

				// Check if the item matches the selectedOrderId on bundleTarget if it exists
				const matchesSelectedOrderId =
					bundleTarget?.selectedOrderId &&
					item.orderIds?.some(
						(orderIdPair) => orderIdPair.orderId === Number(bundleTarget?.selectedOrderId)
					)

				return bundleTarget.selectedOrderId ? matchesSelectedOrderId : hasCommonOrderId
			})

		return updatedList
	}

	useEffect(() => {
		onScannedArticleRef.current = onScannedArticle
	}, [onScannedArticle])

	usePhysicalScanner((barcode) => {
		onScannedArticleRef.current(barcode)
	}, [])

	useEffect(() => {
		if (errorMessage === '409') {
			setNoAlternativeLocationError(true)
		}
	}, [errorMessage, dispatch])

	useEffect(() => {
		dispatch(fetchPickingFilter())
		dispatch(fetchPickingList({ nextBusinessDay: false }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const loadingSpinner = loading ? <LoadingSpinner /> : ''
	const showCompletePickingList = !pickingList?.rows?.some(
		(i) => i.pickingStatus === pickingListRowStatus.None,
	)

	const inBundling = bundleTarget && bundleArticles?.length > 0

	const listToMap = filterList(bundleTarget, pickingList)

	return (
		<>
			<Container className="picking">
				{loadingSpinner}
				<div className="justify-content-center">
					{showPrinting && <Printing onPrint={onPrintPicking} />}

					<Col xs={12} sm={12}>
						<Row className="justify-content-center">
							{showScanner && (
								<BarCodeScanner
									onScannedResult={onScannedArticle}
									isPicking={true}
									scannedArticles={scannedArticles}
								/>
							)}
							{pickingList && (
								<div className="pickingListInformation">
									<div>
										Picking group: {pickingList?.pickingGroupDisplayText}
									</div>
									<div>Sequence number: {pickingList?.sequenceNumber}</div>
								</div>
							)}

							{hasPickingList &&
								listToMap?.map((item, index) => (
									<Card key={index} className="row-card">
										<PickingRow
											selected={item.stockLocation === selectedLocation}
											pickedValue={calculatePickedValue(item.stockLocation)}
											totalValue={item.quantity}
											item={item}
											handlePicked={handlePicked}
											rowIndex={item.originalRowIndex || index}
											onBundle={onHandleBundle}
											disabled={bundleTarget}
											bundleTarget={bundleTarget}
											pickingListContainAccessory={pickingListContainAccessory}
											onChangeParcelAmount={handleOnChangeParcelAmount}
											selectOrderOnBundleTarget={onHandleSelectedBundleTargetOrder}
											selectedBundleTargetOrderId={bundleTarget?.selectedOrderId}
											isBundleTarget={
												(item.originalRowIndex === bundleTarget?.rowIndex) &&
												item.articleId === bundleTarget?.articleId
												&& item.stockLocation === bundleTarget?.stockLocation
											}
											toBeBundled={bundleArticles.some(
												(bundleItem) =>
													bundleItem.rowIndex === item.originalRowIndex &&
													bundleItem.articleId === item.articleId &&
													bundleItem.stockLocation === item.stockLocation
											)}
										/>
									</Card>
								))}
						</Row>

						{!hasPickingList && !loading && (
							<PickingListCreateForm
								pickingGroups={pickingGroups}
								selectedGroup={selectedGroup}
								pickingBuildings={pickingBuildings}
								selectedBuilding={selectedBuilding}
								selectedFloor={selectedFloor}
								selectedProductGroup={selectedProductGroup}
								selectedAccessoryOption={selectedAccessoryOption}
								onChangePickingGroup={onChangePickingGroup}
								onChangePickingBuilding={onChangePickingBuilding}
								onChangePickingFloor={onChangePickingFloor}
								onChangePickingProductGroup={onChangePickingProductGroup}
								onChangePickingAccessory={onChangePickingAccessory}
							/>
						)}

					</Col>
				</div>
				{warnOnGoHome && (
					<ConfirmationWindow
						title="Leave?"
						message="Pickinglist is still active, leave anyway?"
						confirmLabel={'Yes'}
						cancelLabel={'No'}
						onCancel={() => setWarnOnGoHome(false)}
						onConfirm={goHome}
					/>
				)}
			</Container>
			<FloatingButtonWrapper>
				{
					inBundling &&
					<Button variant="warning" onClick={performBundling} size="lg" disabled={!bundleArticles?.length >= 2}>
						Bundle selected
					</Button>
				}
				{hasPickingList && !inBundling && (
					<Button variant="info" onClick={togglePrint} size="lg" disabled={bundleTarget}>
						Print labels
					</Button>
				)}
				{!hasPickingList && !inBundling && !loading && (
					<Button
						variant="success"
						disabled={!selectedGroup}
						onClick={startNewList}
						size="lg"
					>
						Start new pickinglist
					</Button>
				)}
				{hasPickingList && !inBundling && showCompletePickingList && (
					<Button variant="success" onClick={savePicking} size="lg">
						Complete picking list
					</Button>
				)}
				<Button
					className="btnScan"
					variant="secondary"
					type="button"
					id={'switchInputType'}
					onClick={toggleScanner}
				>
					<img src={barcode} alt="barcode input"></img>
				</Button>
				<Button variant="danger" size="lg" onClick={handleGoHome}>
					Home
				</Button>
			</FloatingButtonWrapper>
			{
				noAlternativeLocationError &&
				<SelectRowCorrection
					title="Correction alert"
					disableRemoveAmount={correctionData.PickedQuantity === '0'}
					onClickRemoveAmount={removeAmountFromList}
					onClickRemoveRow={removeRowFromList}
					onClose={onCloseLocationError}
				/>
			}
		</>
	)
}

export default Picking
