import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import { Alert } from 'react-bootstrap'
import Numpad from 'components/Numpad'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import LoadingSpinner from 'components/LoadingSpinner'
import BarCodeScanner from 'features/BarCodeScanner'
import { getPrefixIfUserWarehouse, usePrevious } from 'lib/utils'
import { useNavigate } from 'react-router-dom'
import FloatingNumpad from 'components/FloatingNumpad'
import { toggleShowPrinting } from 'features/Printing/duck'
import If from 'components/If'
import Printing from 'features/Printing/Printing'
import usePrinters from 'lib/hooks/usePrinters'
import SvgBarcodes from 'assets/svgs/svgr/svgr/svgr/Barcodes'

import { fetchStockArticles, setLocation, resetLocation } from './duck'

import { toggleShowScanner } from 'features/BarCodeScanner/duck'

import './style.css'

const StockLocationSearch = () => {
	const dispatch = useDispatch()

	const loading = useSelector((state) => state.stockLocationSearch.loading)
	const completed = useSelector((state) => state.stockLocationSearch.completed)
	const articles = useSelector((state) => state.stockLocationSearch.articles)
	const location = useSelector((state) => state.stockLocationSearch.location)
	const showScanner = useSelector((state) => state.barCodeScanner.showScanner)
	const user = useSelector((state) => state.login.user)
	const navigate = useNavigate()
	const prevLoading = usePrevious(loading)
	const showPrinting = useSelector((state) => state.printing.showPrinting)
	const [quantity, setQuantity] = React.useState('')
	const [editing, setEditing] = React.useState(false)

	const { printStockLocation } = usePrinters()

	const onNumPadClicked = (value, keyCode) => {
		if (value === null && keyCode !== 8) {
			return
		}

		if (keyCode === 8) {
			dispatch(setLocation(location.slice(0, -1)))
		} else {
			dispatch(setLocation(location + value))
		}
	}

	const goBack = () => {
		dispatch(resetLocation())
		navigate(-1)
	}

	const onClear = () => {
		dispatch(resetLocation())
	}

	const onSubmit = (e) => {
		if (e) e.preventDefault()

		const stockLocation = getPrefixIfUserWarehouse(user, location)

		dispatch(setLocation(stockLocation))
		dispatch(fetchStockArticles(stockLocation))
	}

	const toggleScanner = () => {
		dispatch(toggleShowScanner())
	}

	const onNewScanResult = (decodedText, decodedResult) => {
		dispatch(setLocation(decodedText))
	}

	const onNumpadChange = (value) => {
		setQuantity(value)
	}

	const onConfirmNumpad = () => {
		setEditing(false)
	}

	const onClearNumpad = () => {
		setQuantity('')
	}

	const onChange = (value) => {
		setQuantity(value)
	}

	const onEdit = () => {
		setEditing(true)
	}

	const togglePrint = () => {
		dispatch(toggleShowPrinting())
	}

	const onPrintStockLocationLabel = (selectedPrinter) => {
		if (quantity === '') {
			setQuantity('1')
		}
		printStockLocation(selectedPrinter, quantity, location)
		setQuantity('')
	}

	useEffect(() => {
		if (completed && articles?.length) {
			if (prevLoading && articles?.length) {
				navigate('/articlelist/location')
			}
		}
	}, [completed, articles, loading, navigate, prevLoading])

	return (
		<>
			<Form className="stockLocationSearch" onSubmit={onSubmit}>
				<Form.Group>
					<div className="input-group-append">
						<Form.Control
							type="text"
							name="stockLocation"
							readOnly
							value={location}
							placeholder="Search location...."
						/>
						<button
							className="btn btn-outline-secondary"
							type="button"
							id={'switchInputType'}
							onClick={toggleScanner}
						>
							<SvgBarcodes />
						</button>
					</div>
				</Form.Group>
				<Form.Group>
					<Numpad
						showStockButton={true}
						onClear={onClear}
						onClick={onNumPadClicked}
						onSend={onSubmit}
						buttonText="Search"
						hideExtraButton={false}
					/>
				</Form.Group>
				<div className="printBtn">
					<Button
						disabled={!location}
						variant="info"
						onClick={togglePrint}
						size="lg"
					>
						Print labels
					</Button>
				</div>

				<Row className="justify-content-center">
					<ButtonGroup size="lg">
						<Button variant="danger" onClick={goBack}>
							Home
						</Button>
						<Button variant="outline-dark" onClick={onClear}>
							Clear
						</Button>
					</ButtonGroup>
				</Row>
				{loading ? <LoadingSpinner /> : ''}
				{completed && articles.length === 0 ? 'No articles found' : ''}
				{showScanner && <BarCodeScanner onScannedResult={onNewScanResult} />}
			</Form>

			<If condition={showPrinting}>
				<Printing onPrint={onPrintStockLocationLabel}>
					<Alert variant="info" className="location">
						Stock Location: {location} selected
					</Alert>

					<Form.Group>
						<Form.Control
							placeholder="Enter quantity"
							size="xxl"
							type="number"
							className="editable"
							onChange={onChange}
							name="quantity"
							onClick={onEdit}
							value={quantity}
						/>
					</Form.Group>

					<If condition={editing}>
						<FloatingNumpad
							onChange={onNumpadChange}
							text={quantity}
							onSend={onConfirmNumpad}
							onClear={onClearNumpad}
							showStockButton={false}
							hideExtraButton={true}
						/>
					</If>
				</Printing>
			</If>
		</>
	)
}

export default StockLocationSearch
