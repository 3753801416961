const initialState = {
	loading: false,
	error: false,
	completed: false,
	currentStockLocation: '',
	newStockLocation: '',
	warning: false
}

const CREATE_LOCATION_MOVE = 'features/StockLocationMove/CREATE_LOCATION_MOVE'
const CREATE_LOCATION_MOVE_SUCCESS = 'features/StockLocationMove/CREATE_LOCATION_MOVE_SUCCESS'
const CREATE_LOCATION_MOVE_FAILED = 'features/StockLocationMove/CREATE_LOCATION_MOVE_FAILED'

const RESET_ERROR_MESSAGE = 'features/StockLocationMove/RESET_ERROR_MESSAGE'
const RESET_COMPLETED = 'features/StockLocationMove/RESET_COMPLETED'

export const createLocationMove = (currentStockLocation, newStockLocation) => {
	return {
		types: [CREATE_LOCATION_MOVE, CREATE_LOCATION_MOVE_SUCCESS, CREATE_LOCATION_MOVE_FAILED],
		callApi: {
			endpoint: 'api/StockBulkRelocation',
			method: 'POST',
			data: { fromLocation: currentStockLocation, toLocation: newStockLocation }
		}
	}
}

export const resetErrorMessage = () => {
	return {
		type: RESET_ERROR_MESSAGE,
	}
}

export const resetCompleted = () => {
	return {
		type: RESET_COMPLETED,
	}
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case CREATE_LOCATION_MOVE:
			return {
				...state,
				loading: true,
				error: false,
				completed: false,
			}
		case CREATE_LOCATION_MOVE_SUCCESS:
			return {
				...state,
				loading: false,
				completed: true,
				error: false,
			}
		case CREATE_LOCATION_MOVE_FAILED:
			return {
				...state,
				loading: false,
				completed: false,
				error: true,
			}
		case RESET_ERROR_MESSAGE:
			return {
				...state,
				error: false,
			}
		case RESET_COMPLETED:
			return {
				...state,
				completed: false,
			}
		default:
			return state
	}
}