const initialState = {
	loading: false,
	items: [],
}

const FETCH_DIMENSIONS = 'features/TruckDimension/FETCH_DIMENSIONS'
const FETCH_DIMENSIONS_SUCCESS =
	'features/TruckDimension/FETCH_DIMENSIONS_SUCCESS'
const FETCH_DIMENSIONS_FAILED =
	'features/TruckDimension/FETCH_DIMENSIONS_FAILED'

export const fetchDimensions = () => {
	return {
		types: [
			FETCH_DIMENSIONS,
			FETCH_DIMENSIONS_SUCCESS,
			FETCH_DIMENSIONS_FAILED,
		],
		callApi: {
			endpoint: 'api/truckTyreDimensions',
		},
	}
}

const reducer = (state = initialState, { type, data }) => {
	switch (type) {
		case FETCH_DIMENSIONS:
			return {
				...state,
				loading: true,
			}

		case FETCH_DIMENSIONS_SUCCESS:
			return {
				...state,
				loading: false,
				items: data,
			}

		case FETCH_DIMENSIONS_FAILED:
			return {
				...state,
				loading: false,
			}

		default:
			return state
	}
}

export default reducer
