import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ArticleTitle from 'components/ArticleTitle'
import ArticleLocationInfo from 'components/ArticleLocationInfo'
import LoadingOverlay from 'components/LoadingOverlay'
import ArticleIdInfo from 'components/ArticleIdInfo'
import { useNavigate, useParams } from 'react-router'

import { Alert, Form } from 'react-bootstrap'
import FloatingNumpad from 'components/FloatingNumpad'
import If from 'components/If'
import Printing from 'features/Printing/Printing'
import usePrinters from 'lib/hooks/usePrinters'
import { fetchStock } from './duck'
import { toggleShowPrinting } from 'features/Printing/duck'
import { useGetArticleQuery } from 'api/article'
import FloatingButtonWrapper from 'components/FloatingButtonWrapper'

import './style.css'

const Article = () => {

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const articleStockInfo = useSelector(state => state.article.articleStockInfo)

	const { articleId, stockLocation, dotYear } = useParams()

	const { data: article, isFetching } = useGetArticleQuery(articleId)

	const loading = useSelector(state => state.article.loading) || isFetching

	const showPrinting = useSelector(state => state.printing.showPrinting)

	const [quantity, setQuantity] = React.useState('')
	const [editing, setEditing] = React.useState(false)

	const { printArticleLabel } = usePrinters()

	useEffect(() => {
		dispatch(fetchStock(articleId, stockLocation, dotYear))
	}, [dispatch, articleId, stockLocation, dotYear])

	const onNavigateClicked = (navString) => {
		if (article) {
			navigate(`/${navString}/${article.articleId}/${stockLocation}/${dotYear}`)
		}
	}

	const onPrintArticleInfoLabel = (selectedPrinter) => {
		printArticleLabel(selectedPrinter, articleId, quantity)
	}

	const onChange = (value) => {
		setQuantity(value)
	}

	const onEdit = () => {
		setEditing(true)
	}

	const onConfirmNumpad = () => {
		setEditing(false)
	}

	const onClearNumpad = () => {
		setQuantity('')
	}

	const onNumpadChange = value => {
		setQuantity(value)
	}

	const togglePrint = () => {
		dispatch(toggleShowPrinting())
	}

	const loadingSpinner = loading ? <LoadingOverlay /> : ''

	return (
		<>
			<Container className="article">
				<Row className="justify-content-center">
					<Card style={{ width: '23rem' }}>
						<Card.Body>
							{article ? <ArticleTitle item={article} dotYear={dotYear} /> : ''}
							{articleStockInfo ? <ArticleLocationInfo location={stockLocation} quantity={articleStockInfo.quantityInStock} /> : ''}
							{article ? <ArticleIdInfo articleId={article.articleId} supplierArticleId={article.supplierArticleId} /> : ''}
						</Card.Body>
					</Card>
				</Row>
				<ButtonGroup vertical size="xxl" aria-label="First group">
					<Button variant="outline-dark" onClick={() => { onNavigateClicked('stockcorrection') }}>Correct quantity</Button>
					<Button variant="outline-dark" onClick={() => { onNavigateClicked('movearticle') }}>Move article</Button>
				</ButtonGroup>
				{loadingSpinner}
				<If condition={showPrinting}>
					<Printing onPrint={onPrintArticleInfoLabel}>
						<Alert variant="info" className="location">
							Article: {articleId} selected
						</Alert>
						<Form.Group>
							<Form.Control
								placeholder="Enter quantity"
								size="xxl"
								type="number"
								className="editable"
								onChange={onChange}
								name="quantity"
								onClick={onEdit}
								value={quantity} />
						</Form.Group>

						<If condition={editing}>
							<FloatingNumpad
								onChange={onNumpadChange}
								text={quantity}
								onSend={onConfirmNumpad}
								onClear={onClearNumpad}
								showStockButton={false}
								hideExtraButton={true}
							/>
						</If>
					</Printing>
				</If>
			</Container>
			<FloatingButtonWrapper showHome={true}>
				<div className="printBtn">
					<Button disabled={!stockLocation} variant="info" onClick={togglePrint} size="lg">Print labels</Button>
				</div>
			</FloatingButtonWrapper>
		</>

	)
}

export default Article
