import './style.css'
import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import FloatingNumpad from 'components/FloatingNumpad'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FloatingWindow from 'components/FloatingWindow'
import PropTypes from 'prop-types'
import LoadingSpinner from 'components/LoadingSpinner'
import ConfirmationWindow from 'components/ConfirmationWindow/ConfirmationWindow'
import InformationBox from 'components/InformationBox/InformationBox'
import If from 'components/If'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from '../MessageBox/duck'

import { createLocationMove, resetErrorMessage, resetCompleted } from './duck'

const StockLocationMove = ({
	currentStockLocation
}) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [editing, setEditing] = useState(null)
	const [currentValue, setCurrentValue] = useState('')
	const [warning, setWarning] = useState(false)
	const [newStockLocation, setNewStockLocation] = useState('')
	const error = useSelector((state) => state.stockLocationMove.error)
	const loading = useSelector((state) => state.stockLocationMove.loading)
	const completed = useSelector((state) => state.stockLocationMove.completed)

	const locationSearch = useSelector((state) => state.stockLocationSearch)
	const containsQuantityReserved = locationSearch.articles.filter(a => a.quantityReserved > 0)

	let warningMessage = 'Are you sure you want to move the stock location from ' + currentStockLocation + ' to ' + newStockLocation + '?'

	const handleOnSubmit = () => {
		if (newStockLocation !== '' && newStockLocation !== currentStockLocation) {
			setWarning(true)
		} else {
			dispatch(showMessage('Warning', 'Please enter a new stock location'))
		}
	}

	const onSubmit = () => {
		dispatch(createLocationMove(currentStockLocation, newStockLocation))
		setWarning(false)
	}

	const onEditHandler = (name) => {
		setCurrentValue(name.target.value)
		setEditing(name.target.name)
	}

	const onChangeHandler = (value) => {
		setCurrentValue(value)
		onChange(value)
	}

	const onNumpadChange = (value) => {
		onChangeHandler(value)
	}

	const onConfirmNumpad = () => {
		setEditing(null)
	}

	const onClearNumpad = () => {
		onChangeHandler('')
	}

	const onChange = (value) => {
		setNewStockLocation(value)
	}

	const onBack = () => {
		navigate(-1)
	}

	useEffect(() => {
		if (error) {
			dispatch(showMessage('Error', 'Failed to move stock location'))
			dispatch(resetErrorMessage())
		}

	}, [error, completed, dispatch])

	useEffect(() => {
		if (completed && !loading) {
			navigate(-1)
			dispatch(resetCompleted())
		}
	}, [completed, loading, navigate, dispatch])

	return (
		<Container className="StockLocationMove">
			<FloatingWindow className="justify-content-center" closeOutSide={false} draggable={false} showHeader={true} fullSize={true} title='Move Stock'>
				<Row className="justify-content-center">
					<Col xs={12} sm={12}>
						<div>From Location</div>
						<Form.Group controlId="currentStockLocation">
							<Form.Control
								name="currentStockLocation"
								type="string"
								size="xxl"
								placeholder="Current Stock Location"
								readOnly
								value={currentStockLocation}
							/>
						</Form.Group>

						<div>To Location</div>
						<Form.Group controlId="newStockLocation">
							<Form.Control
								name="newStockLocation"
								type="string"
								size="xxl"
								placeholder="New Stock Location"
								className="editable"
								onClick={onEditHandler}
								onChange={onChangeHandler}
								readOnly
								value={newStockLocation}
							/>
						</Form.Group>

						<If condition={containsQuantityReserved?.length > 0}>
							<InformationBox message={'Unable to move, stock location contains reserved quantities'} />
						</If>


						<Form.Group>
							<ButtonGroup size="lg">
								<Button variant="warning" size="lg" onClick={onBack}>
									Cancel
								</Button>
								<Button variant="primary" type="submit" onClick={handleOnSubmit} disabled={containsQuantityReserved?.length > 0}>
									Submit
								</Button>
							</ButtonGroup>
						</Form.Group>
					</Col>
				</Row>
				{loading ? <LoadingSpinner /> : ''}
			</FloatingWindow>

			{editing ? (
				<FloatingNumpad
					onChange={onNumpadChange}
					text={currentValue}
					onSend={onConfirmNumpad}
					onClear={onClearNumpad}
					showStockButton={true}
					hideExtraButton={false}
				/>
			) : (
				''
			)}

			{warning && (
				<ConfirmationWindow
					title="warning"
					message={warningMessage}
					confirmLabel={'Yes'}
					cancelLabel={'No'}
					onCancel={() => setWarning(false)}
					onConfirm={onSubmit}
				/>
			)}
		</Container>
	)
}

StockLocationMove.propTypes = {
	currentStockLocation: PropTypes.string
}

export default StockLocationMove