import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Form from 'react-bootstrap/Form'

import { fetchDimensions } from './duck'

import './style.css'

const TruckDimension = ({ selectedItem, onChange, disabled }) => {
	const dispatch = useDispatch()
	const loading = useSelector((state) => state.truckDimension.loading)
	const items = useSelector((state) => state.truckDimension.items)

	useEffect(() => {
		if (!items.length) {
			dispatch(fetchDimensions())
		}
	}, [dispatch, items])

	return (
		<div className="truckDimension">
			<Form.Group>
				<Form.Control
					name="truckDimension"
					onChange={onChange}
					value={selectedItem}
					disabled={loading || disabled}
					as="select"
					size="lg"
				>
					<option value="">- Select dimension -</option>
					{items.map((item) => (
						<option key={item.key} value={item.key}>
							{item.value}
						</option>
					))}
				</Form.Control>
			</Form.Group>
		</div>
	)
}

TruckDimension.propTypes = {
	selectedItem: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
}

export default TruckDimension
