import React, { useMemo } from 'react'
import { Button, Form } from 'react-bootstrap'
import FloatingWindow from 'components/FloatingWindow'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'features/MessageBox/duck'
import PropTypes from 'prop-types'

import { toggleShowPrinting, setSelectedPrinter } from './duck'

import './style.css'

const Printing = ({ onPrint, children }) => {
	const dispatch = useDispatch()
	const printers = useSelector((state) => state.printing.printers)
	const selectedPrinter = useSelector((state) => state.printing.selectedPrinter)

	const groupedPrinters = useMemo(() => {
		return printers.reduce((acc, printer) => {
			if (!acc[printer.warehouse]) {
				acc[printer.warehouse] = []
			}
			acc[printer.warehouse].push(printer)
			return acc
		}, {})
	}, [printers])

	const onChangePrinter = (e) => {
		dispatch(setSelectedPrinter(e.target.value))
	}

	const onHandlePrint = () => {
		onPrint(selectedPrinter)
		onHandleClose()
		dispatch(
			showMessage('Print', `Print job sent to printer '${selectedPrinter}'`)
		)
	}

	const onHandleClose = () => {
		dispatch(toggleShowPrinting())
	}

	return (
		<div className="floatingWindow">
			<FloatingWindow
				closeOutSide={true}
				fullSize={true}
				showHeader={true}
				draggable={false}
				onClose={onHandleClose}
				onClosed={onHandleClose}
			>
				<div className="Printing">
					{children}
					<Form.Control
						disabled={false}
						name="printers"
						onChange={onChangePrinter}
						value={selectedPrinter}
						as="select"
						size="lg"
					>
						<option value="">- Select printer -</option>
						{Object.entries(groupedPrinters).map(([warehouse, printers]) => (
							<optgroup key={warehouse} label={warehouse}>
								{printers.map((printer) => (
									<option key={printer.name} value={warehouse ? warehouse + '/' + printer.name : printer.name}>
										{printer.name}
									</option>
								))}
							</optgroup>
						))}
					</Form.Control>
					<Button
						className="printBtn"
						disabled={!selectedPrinter}
						variant="info"
						size="md"
						onClick={onHandlePrint}
					>
						Print with selected printer
					</Button>
				</div>
			</FloatingWindow>
		</div>
	)
}

Printing.propTypes = {
	children: PropTypes.node,
	onPrint: PropTypes.func,
}

export default Printing
