const initialState = {
	loading: false,
	brands: null,
	showNumpad: true,
	showBrands: false,
	articles: null,
	brandId: 0,
	modelId: 0,
	width: '',
	aspectRatio: '',
	diameter: '',
	bolts: '',
	circle: '',
	articleId: '',
	searchArticleId: false,
	inchSearch: false,
	selectedArticleType: 1,
	truckDimension: '',
	loaderDimension: '',
	truckRimDimension: '',
	disableNumpad: false,
}

const FETCH_BRANDS = 'features/ArticleSearch/FETCH_BRANDS'
const FETCH_BRANDS_SUCCESS = 'features/ArticleSearch/FETCH_BRANDS_SUCCESS'
const FETCH_BRANDS_FAILED = 'features/ArticleSearch/FETCH_BRANDS_FAILED'

const FETCH_ARTICLES = 'features/ArticleSearch/FETCH_ARTICLES'
const FETCH_ARTICLES_SUCCESS = 'features/ArticleSearch/FETCH_ARTICLES_SUCCESS'
const FETCH_ARTICLES_FAILED = 'features/ArticleSearch/FETCH_ARTICLES_FAILED'

const DIMENSION_CHANGE = 'features/ArticleSearch/DIMENSION_CHANGE'
const ITEM_CHANGE = 'features/ArticleSearch/ITEM_CHANGE'
const CLEAR_SEARCH = 'features/ArticleSearch/CLEAR_SEARCH'
const TOGGLE_SEARCH_TYPE = 'features/ArticleSearch/TOGGLE_SEARCH_TYPE'
const SET_DISABLE_NUMPAD = 'features/ArticleSearch/TOGGLE_DISABLE_NUMPAD'
const CHANGE_ARTICLE_TYPE = 'features/ArticleSearch/CHANGE_ARTICLE_TYPE'

export const fetchBrands = (
	width,
	aspectRatio,
	diameter,
	bolts,
	circle,
	articleType,
) => {
	const criteria = {
		width,
		aspectRatio,
		diameter,
		bolts,
		circle,
		articleType,
	}

	return {
		types: [FETCH_BRANDS, FETCH_BRANDS_SUCCESS, FETCH_BRANDS_FAILED],
		callApi: {
			endpoint: 'api/brands/',
			method: 'GET',
			data: criteria,
		},
	}
}

export const fetchArticles = (
	width,
	aspectRatio,
	diameter,
	bolts,
	circle,
	brandId,
	patternModelId,
	articleType,
) => {
	const criteria = {
		width,
		aspectRatio,
		diameter,
		bolts,
		circle,
		brandId,
		patternModelId,
		articleType,
	}

	return {
		types: [FETCH_ARTICLES, FETCH_ARTICLES_SUCCESS, FETCH_ARTICLES_FAILED],
		callApi: {
			endpoint: 'api/articles/',
			method: 'GET',
			data: criteria,
		},
	}
}

export const fetchArticlesBySearchText = (articleSearchText) => {
	const criteria = {
		articleSearchText,
	}

	return {
		types: [FETCH_ARTICLES, FETCH_ARTICLES_SUCCESS, FETCH_ARTICLES_FAILED],
		callApi: {
			endpoint: 'api/articles/',
			method: 'GET',
			data: criteria,
		},
	}
}

export const itemChange = (name, value) => {
	return {
		type: ITEM_CHANGE,
		data: {
			name: name,
			value: value,
		},
	}
}

export const dimensionChange = (
	width,
	aspectRatio,
	diameter,
	bolts,
	circle,
	inchSearch,
) => {
	return {
		type: DIMENSION_CHANGE,
		data: {
			width,
			aspectRatio,
			diameter,
			bolts,
			circle,
			inchSearch,
		},
	}
}

export const clearSearch = () => {
	return {
		type: CLEAR_SEARCH,
	}
}

export const toggleSearchType = () => {
	return {
		type: TOGGLE_SEARCH_TYPE,
	}
}

export const setDisableNumpad = (value) => {
	return {
		type: SET_DISABLE_NUMPAD,
		data: {
			value,
		},
	}
}

export const changeArticleType = (articleType, disableNumpad) => {
	return {
		type: CHANGE_ARTICLE_TYPE,
		data: {
			articleType,
			disableNumpad,
		},
	}
}

const reducer = (state = initialState, { type, data }) => {
	switch (type) {
		case FETCH_BRANDS:
			return {
				...state,
				loading: true,
			}
		case FETCH_BRANDS_SUCCESS:
			return {
				...state,
				loading: false,
				brands: data,
				showNumpad: false,
				showBrands: true,
			}
		case FETCH_BRANDS_FAILED:
			return {
				...state,
				loading: false,
				brands: [],
			}
		case FETCH_ARTICLES:
			return {
				...state,
				loading: true,
			}
		case FETCH_ARTICLES_SUCCESS:
			return {
				...state,
				loading: false,
				articles: data,
			}
		case FETCH_ARTICLES_FAILED:
			return {
				...state,
				loading: false,
				articles: [],
			}
		case ITEM_CHANGE:
			return {
				...state,
				[data.name]: data.value,
			}
		case CLEAR_SEARCH:
			return {
				...state,
				brands: null,
				showNumpad: true,
				showBrands: false,
				brandId: 0,
				modelId: 0,
				articles: null,
				width: '',
				aspectRatio: '',
				diameter: '',
				bolts: '',
				circle: '',
				articleId: '',
				inchSearch: false,
				truckDimension: '',
				loaderDimension: '',
				truckRimDimension: '',
			}
		case DIMENSION_CHANGE:
			return {
				...state,
				width: data.width,
				aspectRatio: data.aspectRatio,
				diameter: data.diameter,
				bolts: data.bolts,
				circle: data.circle,
				inchSearch: data.inchSearch,
			}
		case TOGGLE_SEARCH_TYPE:
			return {
				...state,
				searchArticleId: !state.searchArticleId,
				selectedArticleType: 1,
				disableNumpad: false,
			}
		case SET_DISABLE_NUMPAD:
			return {
				...state,
				disableNumpad: data.value,
			}
		case CHANGE_ARTICLE_TYPE:
			return {
				...state,
				selectedArticleType: data.articleType,
				disableNumpad: data.disableNumpad,
				searchArticleId: false,
			}

		default:
			return state
	}
}
export default reducer
