import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'react-bootstrap/Button'
import { logout } from 'features/Login/duck'
import { useNavigate } from 'react-router-dom'
import './style.css'

const Start = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const featureSettings = useSelector(
		(state) => state.app.settings.featureSettings,
	)

	const onNavigationClicked = (historyString) => {
		navigate(`/${historyString}`)
	}

	const onLogout = () => {
		dispatch(logout())
	}

	return (
		<div className="start">
			<Button
				variant="danger"
				block
				onClick={() => onNavigationClicked('stocklocation')}
			>
				Stocklocation
			</Button>
			<Button
				variant="dark"
				block
				onClick={() => onNavigationClicked('articlesearch')}
			>
				Product
			</Button>
			<Button
				variant="secondary"
				block
				onClick={() => onNavigationClicked('inboundDelivery')}
			>
				Inbound delivery
			</Button>

			{featureSettings?.enablePickingCategory && (
				<Button
					variant="primary"
					block
					onClick={() => onNavigationClicked('picking')}
				>
					Picking
				</Button>
			)}

			<Button
				variant="warning"
				block
				onClick={() => onNavigationClicked('rollingInventory')}
			>
				Rolling inventory
			</Button>
			<Button variant="info" block onClick={onLogout}>
				Logout
			</Button>
		</div>
	)
}

export default Start
