import React, { useEffect, useCallback, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Col, Row, Form, Button, Container } from 'react-bootstrap'
import './style.css'
import FloatingButtonWrapper from 'components/FloatingButtonWrapper'
import ArticleLocationInfo from 'components/ArticleLocationInfo'
import ArticleIdInfo from 'components/ArticleIdInfo'
import {
	fetchStockTaking,
	processStockTaking,
	saveNewStockTaking,
	updateStockTakingRow,
} from './duck'
import LoadingOverlay from 'components/LoadingOverlay'
import { useNavigate, useParams } from 'react-router-dom'
import NumpadInput from 'components/NumpadInput/NumpadInput'
import ConfirmationWindow from 'components/ConfirmationWindow/ConfirmationWindow'
import stockTakingStatus from 'enums/stockTakingStatus'
import ArticleStockLocationAdd from 'features/ArticleStockLocationAdd'
import classNames from 'classnames'

import { setValue } from '../ArticleStockLocationAdd/duck'
import PropTypes from 'prop-types'
import { showMessage } from '../MessageBox/duck'

const StockTakingRow = ({
	item,
	onChange,
	onToggleCheck,
	isProcessed,
	isChecked,
}) => {
	const onChangeHandler = (name, value) => {
		onChange(item.id, name, value)
	}

	const onToggleCheckHandler = () => {
		onToggleCheck(item.id)
	}

	return (
		<Card className="stock-card" onClick={onToggleCheckHandler}>
			<Card.Body className={classNames({ rowChecked: isChecked })}>
				<div className="articleText">{item.articleText}</div>
				<ArticleIdInfo
					articleId={item.articleId}
					supplierArticleId={item.supplierArticleId}
				/>
				<ArticleLocationInfo
					location={item.stockLocation}
					quantity={item.quantity}
				/>

				<div className="input row">
					<div className="group">
						<Form.Group controlId={`quantity-${item.id}`}>
							<NumpadInput
								label="New Quantity"
								name="newQuantity"
								type="number"
								value={item.newQuantity}
								onChange={onChangeHandler}
								disabled={isProcessed}
							/>
						</Form.Group>
					</div>
					<div className="group">
						<Form.Group controlId={`dotyear-${item.id}`}>
							<NumpadInput
								label="DOT"
								name="dotYear"
								type="number"
								value={item.dotYear}
								onChange={onChangeHandler}
								disabled={isProcessed}
							/>
						</Form.Group>
					</div>
				</div>
			</Card.Body>
		</Card>
	)
}

const StockTaking = () => {
	const dispatch = useDispatch()
	const { sequenceNumber } = useParams()
	const [showConfirmation, setShowConfirmation] = useState(false)
	const [checkedRowIds, setCheckedRowIds] = useState([])
	const [showAddArticleToLocaton, setShowAddArticleToLocation] = useState(false)

	const stockTaking = useSelector((state) => state.stockTaking.stockTakingList)
	const loading = useSelector((state) => state.stockTaking.loading)
	const processed = useSelector((state) => state.stockTaking.processed)
	const isDirty = useSelector((state) => state.stockTaking.isDirty)
	const isProcessed = stockTaking?.status === stockTakingStatus.Processed
	const navigate = useNavigate()
	const submit = useRef(false)

	const loadStocktaking = useCallback(
		(id) => {
			dispatch(fetchStockTaking(id))
		},
		[dispatch],
	)

	const onChange = (rowId, name, value) => {
		dispatch(updateStockTakingRow({ rowId, name, value }))
	}

	const onToggleCheck = (rowId) => {
		if (checkedRowIds.includes(rowId)) {
			setCheckedRowIds(checkedRowIds.filter((id) => id !== rowId))
		} else {
			setCheckedRowIds([...checkedRowIds, rowId])
		}
	}

	const sendProcessStockTaking = () => {
		// dispatch action to complete stocktaking
		submit.current = true
		setShowConfirmation(false)
		dispatch(
			processStockTaking({
				sequenceNumber: stockTaking.sequenceNumber,
			}),
		)
	}

	const onCompleteStockTakingClick = () => {
		if(!loading)
			setShowConfirmation(true)
	}

	const onAddArticleToLocation = () => {
		if(!loading) {
			dispatch(setValue('completed', false))
			setShowAddArticleToLocation(true)
		}
	}

	const onCloseAddArticleToLocation = () => {
		dispatch(fetchStockTaking(sequenceNumber))
		setShowAddArticleToLocation(false)
	}

	const onSave = () => {
		const payload = {
			sequenceNumber: stockTaking.sequenceNumber,
			rows: stockTaking.rows.map((item) => ({
				id: item.id,
				newStockLocation: item.newStockLocation,
				newQuantity: item.newQuantity,
				dotYear: item.dotYear,
				comment: item.comment,
			})),
		}

		dispatch(saveNewStockTaking(payload))
	}

	useEffect(() => {
		// reload stocktaking if it was processed
		if (processed) loadStocktaking(sequenceNumber)
	}, [loadStocktaking, processed, sequenceNumber])

	useEffect(() => {
		// load stocktaking based on sequenceNumber from url params
		if (sequenceNumber) loadStocktaking(sequenceNumber)
	}, [loadStocktaking, sequenceNumber])

	useEffect(() => {
		if (!loading && submit.current) {
			submit.current = false
			dispatch(showMessage('Info', 'Inventory items have been saved'))
			navigate('/rollinginventory')
		}
	}, [loading, dispatch, navigate])

	return (
		<>
			<Container className="StockTaking">
				{stockTaking && !loading && (
					<div className="StockTakingView">
						<div>
							SequenceNumber: <strong>{stockTaking.sequenceNumber}</strong>
						</div>
						<Row className="justify-content-center">
							<Col xs={12} sm={12}>
								<Row className="justify-content-center">
									{stockTaking.rows?.length &&
										stockTaking.rows.map((item) => (
											<StockTakingRow
												key={item.id}
												item={item}
												onChange={onChange}
												onToggleCheck={onToggleCheck}
												isChecked={checkedRowIds.includes(item.id)}
												isProcessed={isProcessed}
											/>
										))}
								</Row>
							</Col>
						</Row>
					</div>
				)}
				{loading && <LoadingOverlay />}
				{showConfirmation && (
					<ConfirmationWindow
						title="Confirmation"
						message={`Are you sure you want to complete #${sequenceNumber}?`}
						onCancel={() => setShowConfirmation(false)}
						onConfirm={sendProcessStockTaking}
					/>
				)}
			</Container>
			<FloatingButtonWrapper showHome={true}>
				<Button
					variant="primary"
					size="lg"
					onClick={onAddArticleToLocation}
					className={loading ? 'disabled' : ''}
				>
					Add article to location
				</Button>
				{stockTaking && !isProcessed && (
					<>
						{isDirty ? (
							<Button variant="primary" size="lg" onClick={onSave}>
								Save
							</Button>
						) : (
							<Button
								variant="success"
								size="lg"
								onClick={onCompleteStockTakingClick}
								className={loading ? 'disabled' : ''}
							>
								Complete
							</Button>
						)}
					</>
				)}
			</FloatingButtonWrapper>
			{showAddArticleToLocaton && (
				<ArticleStockLocationAdd onClose={onCloseAddArticleToLocation} />
			)}
		</>
	)
}

StockTakingRow.propTypes = {
	item: PropTypes.object,
	onChange: PropTypes.func,
	onToggleCheck: PropTypes.func,
	isProcessed: PropTypes.bool,
	isChecked: PropTypes.bool,
}

export default StockTaking
