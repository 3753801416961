// hook to handle subscription to printers topic and handle online/offline status of printers
// send message to printers topic to get list of active printers
// send message to printer to print a label

import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import { addPrinter, removePrinter } from 'features/Printing/duck'
import { useStomp } from 'lib/stompContext'

const usePrinters = () => {
	const stompConnector = useStomp()

	const dispatch = useDispatch()
	const printers = useSelector((state) => state.printing.printers)
	const printerTopic = 'ActiveMQ.Advisory.Consumer.Queue.>'
	const [managePrinters, setManagePrinters] = useState(false)
	const [isSubscribed, setIsSubscribed] = useState(false)
	const user = useSelector(state => state.login.user)

	const printPickingList = (selectedPrinter, sequenceNumber) => {
		const labelIdsAndQuantities = {
			[sequenceNumber]: sequenceNumber,
			1: 1, // Quantity
		}

		const payload = {
			LabelType: 1, // use for picking list
			LabelIdsAndQuantities: labelIdsAndQuantities,
		}

		stompConnector.sendToQueue(selectedPrinter, JSON.stringify(payload))
	}

	const printStockLocation = (selectedPrinter, quantity, location) => {
		const payload = {
			LabelType: 2, // use for stock location
			LabelIdsAndQuantities: {
				[location]: parseInt(quantity),
			},
		}

		stompConnector.sendToQueue(selectedPrinter, JSON.stringify(payload))
	}

	const printArticleLabel = (selectedPrinter, articleId, quantity) => {
		const labelIdsAndQuantities = {
			[articleId]: quantity,
		}

		const payload = {
			LabelType: 0, // use for article label
			LabelIdsAndQuantities: labelIdsAndQuantities,
		}
		stompConnector.sendToQueue(selectedPrinter, JSON.stringify(payload))
	}

	const setupPrinters = () => {
		setManagePrinters(true)
	}

	const togglePrinterStatus = useCallback(
		(data) => {
			const lastBraceIndex = data.lastIndexOf('}')
			const trimmedJsonString = data.substring(0, lastBraceIndex + 1)
			let jsonObject = null

			try {
				jsonObject = JSON.parse(trimmedJsonString)
			} catch (error) {
				console.error('Error parsing JSON:', error.message)
			}

			const handleAddPrinter = (printerLocation, printerName, connectionId, status) => {
				dispatch(
					addPrinter({
						warehouse: printerLocation,
						name: printerName,
						connectionId: connectionId,
						status: status,
					})
				)
			}

			if (jsonObject?.ConsumerInfo) {
				const { destination, consumerId } = jsonObject.ConsumerInfo
				const locationAndName = destination?.string.split('/')
				const printerLocation = locationAndName[0]
				const printerName = locationAndName[1]
				const connectionId = consumerId?.connectionId

				// Check if the user has a warehouse assigned
				if (user?.warehouseId !== null) {
					// If user has a warehouse assigned, only add printers with matching location
					if (user?.warehouseName === printerLocation) {
						handleAddPrinter(printerLocation, printerName, connectionId, 'online')
					}
				} else {
					// If user doesn't have a warehouse assigned, add all printers
					handleAddPrinter(printerLocation, printerName, connectionId, 'online')
				}
			} else if (jsonObject?.RemoveInfo) {
				const connectionId = jsonObject.RemoveInfo.objectId.connectionId
				dispatch(
					removePrinter({ connectionId: connectionId, status: 'offline' })
				)
			}
		},
		[dispatch, user]
	)

	useEffect(() => {
		if (stompConnector && managePrinters && !isSubscribed && user) {
			stompConnector.onConnected(() => {
				const subscribedSuccessfully = stompConnector.subscribeToTopic(printerTopic, (message) => {
					togglePrinterStatus(message)
				})

				setIsSubscribed(subscribedSuccessfully)
			})
		}
	}, [stompConnector, managePrinters, isSubscribed, togglePrinterStatus, user])

	return {
		setupPrinters,
		printers,
		printPickingList,
		printStockLocation,
		printArticleLabel
	}
}

export default usePrinters
