const initialState = {
	loading: false,
	article: null,
}

const FETCH_STOCK = 'features/Article/FETCH_STOCK'
const FETCH_STOCK_SUCCESS = 'features/Article/FETCH_STOCK_SUCCESS'
const FETCH_STOCK_FAILED = 'features/Article/FETCH_STOCK_FAILED'

export const fetchStock = (articleId, stockLocation, dotYear) => {
	const payload = {
		articleId,
		stockLocation,
		dotYear,
	}

	return {
		types: [FETCH_STOCK, FETCH_STOCK_SUCCESS, FETCH_STOCK_FAILED],
		callApi: {
			data: payload,
			endpoint: 'api/stocklocation/',
			method: 'GET',
		},
	}
}

const reducer = (state = initialState, { type, data }) => {
	switch (type) {

		case FETCH_STOCK:
			return {
				...state,
				stockQuantity: null,
				loading: true,
			}

		case FETCH_STOCK_SUCCESS:
			return {
				...state,
				stockQuantity: data,
				loading: false,
			}

		case FETCH_STOCK_FAILED:
			return {
				...state,
				stockQuantity: null,
			}

		default:
			return state
	}
}
export default reducer
