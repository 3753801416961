import React from 'react'
import { useDispatch } from 'react-redux'
import FloatingWindow from 'components/FloatingWindow'
import Html5QrcodePlugin from 'components/Html5QrcodeScanner'
import Alert from 'react-bootstrap/Alert'
import { Button } from 'react-bootstrap'
import PropTypes from 'prop-types'

import './style.css'

import { toggleShowScanner } from './duck'

const BarCodeScanner = ({
	onScannedResult,
	scannedArticles,
	isPicking,
	onClose,
}) => {
	const dispatch = useDispatch()

	const close = () => {
		if (onClose) onClose()
		else dispatch(toggleShowScanner())
	}

	const handleOnScan = (result) => {
		dispatch(toggleShowScanner())
		onScannedResult(result)
	}

	//Calculate qr box size
	const qrboxFunction = (viewfinderWidth, viewfinderHeight) => {
		let minEdgePercentage = 0.7 // 70%
		let minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight)
		let qrboxSize = Math.floor(minEdgeSize * minEdgePercentage)
		return {
			width: qrboxSize + 50,
			height: qrboxSize - 70,
		}
	}

	return (
		<div className="floatingWindow">
			<FloatingWindow
				closeOutSide={true}
				fullSize={true}
				showHeader={true}
				onClose={close}
				draggable={false}
			>
				<Html5QrcodePlugin
					fps={10}
					qrbox={qrboxFunction}
					disableFlip={false}
					facingMode={'environment'}
					qrCodeSuccessCallback={handleOnScan}
				/>

				{isPicking && scannedArticles && (
					<div className="scrollable-alert">
						<Alert variant="info" className="scannedResult">
							{'Scanned:'}
							{Object.keys(scannedArticles).map((location) => (
								<div key={location}>
									Location: {location}
									{Object.keys(scannedArticles[location]).map((ean) => (
										<div key={ean}>
											EAN: {ean} picked {scannedArticles[location][ean]}{' '}
											{scannedArticles[location][ean] === 1}
										</div>
									))}
								</div>
							))}
						</Alert>
					</div>
				)}
				{isPicking && !scannedArticles && (
					<div className="scrollable-alert">
						<Alert variant="info" className="scannedResult">
							Stock Location: {scannedArticles} selected
						</Alert>
					</div>
				)}
				{isPicking && (
					<Button variant="dark" size="md" onClick={close}>
						Close
					</Button>
				)}
			</FloatingWindow>
		</div>
	)
}

BarCodeScanner.propTypes = {
	onScannedResult: PropTypes.func,
	isPicking: PropTypes.bool,
	onClose: PropTypes.func,
	scannedArticles: PropTypes.any,
}

export default BarCodeScanner
