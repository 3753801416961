import React, { createContext, useContext, useEffect, useState } from 'react'
import StompConnector from './stomp'
import { useSelector } from 'react-redux'
import { usePrevious } from './utils'
import { useLazyGetActiveMqSettingsQuery } from 'api/activeMqSettingsApi'

export const StompContext = createContext(null)

export const useStomp = () => useContext(StompContext)

export const StompProvider = ({ children }) => {
	const [fetchActiveMqCredentials, credentialsResult] = useLazyGetActiveMqSettingsQuery()
	const loggedIn = useSelector((state) => state.login.loggedIn)
	const prevCredentials = usePrevious(credentialsResult.data)
	const prevLoggedIn = usePrevious(loggedIn)

	const [stompConnector, setStompConnector] = useState(null)

	useEffect(() => {
		const credentials = credentialsResult.data

		if (
			(
				credentials?.username &&
				credentials?.password &&
				(!prevCredentials || (!prevLoggedIn && loggedIn)))
		) {
			const connector = new StompConnector(
				credentials.broker + ':61619',
				credentials.username,
				credentials.password,
			)

			connector.connect()
			setStompConnector(connector)

			return () => {
				connector.disconnect()
			}
		}
	}, [credentialsResult.data, loggedIn, prevCredentials, prevLoggedIn])

	useEffect(() => {
		if (!prevLoggedIn && loggedIn)
			fetchActiveMqCredentials()
	}, [fetchActiveMqCredentials, loggedIn, prevLoggedIn])

	useEffect(() => {
		if (prevLoggedIn && !loggedIn && stompConnector) {
			// If the user logs out and stompConnector exists, disconnect StompConnector
			stompConnector.disconnect()
			setStompConnector(null)
		}
	}, [loggedIn, prevLoggedIn, stompConnector])

	return (
		<StompContext.Provider value={stompConnector}>
			{children}
		</StompContext.Provider>
	)
}
