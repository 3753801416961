import './style.css'
import FloatingWindow from 'components/FloatingWindow'
import React from 'react'
import { Button } from 'react-bootstrap'

const ConfirmationWindow = ({
	title,
	message,
	confirmLabel,
	cancelLabel,
	onConfirm,
	onCancel,
}) => {
	// default labels
	confirmLabel = confirmLabel || 'Confirm'
	cancelLabel = cancelLabel || 'Cancel'

	return (
		<div className="ConfirmationWindow">
			<FloatingWindow
				title={title}
				showHeader={true}
				draggable={false}
				closeOutSide={false}
			>
				{message}
				<div className="confirmationButtons">
					<Button variant="danger" size="lg" onClick={onCancel}>
						{cancelLabel}
					</Button>
					<Button variant="success" size="lg" onClick={onConfirm}>
						{confirmLabel}
					</Button>
				</div>
			</FloatingWindow>
		</div>
	)
}

export default ConfirmationWindow
