const initialState = {
	loading: false,
	completed: false,
	supplier: '',
	ean: '',
	supplierArticleNumber: '',
	waybillNumber: '',
	supplierOrderNumber: '',
	error: false,
	showModal: true,
	sort: 1,
	suppliers: null,
	inboundDeliverys: null,
	inboundDeliveryType: 'delivery',
	filter: false
}

const FETCH_INBOUND_DELIVERYS =
	'features/InboundDelivery/FETCH_INBOUND_DELIVERYS'
const FETCH_INBOUND_DELIVERYS_SUCCESS =
	'features/InboundDelivery/FETCH_INBOUND_DELIVERYS_SUCCESS'
const FETCH_INBOUND_DELIVERYS_FAILED =
	'features/InboundDelivery/FETCH_INBOUND_DELIVERYS_FAILED'

const FETCH_SUPPLIERS = 'features/InboundDelivery/FETCH_SUPPLIERS'
const FETCH_SUPPLIERS_SUCCESS = 'features/InboundDelivery/FETCH_SUPPLIERS_SUCCESS'
const FETCH_SUPPLIERS_FAILED = 'features/InboundDelivery/FETCH_SUPPLIERS_FAILED'

const SET_VALUE = 'features/InboundDelivery/SET_VALUE'

//Actions
export const fetchInboundDeliverys = (supplier, ean, supplierArticleNumber, waybillNumber, supplierOrderNumber, sort) => {
	const data = {
		supplierId: supplier,
		ean: ean,
		supplierArticleId: supplierArticleNumber,
		waybillNumber: waybillNumber,
		supplierOrderNumber: supplierOrderNumber,
		sort: sort
	}

	return {
		types: [
			FETCH_INBOUND_DELIVERYS,
			FETCH_INBOUND_DELIVERYS_SUCCESS,
			FETCH_INBOUND_DELIVERYS_FAILED,
		],
		callApi: {
			endpoint: 'api/PurchaseOrderDeliveries',
			method: 'GET',
			data: data,
		},
	}
}

export const fetchSuppliers = () => {
	return {
		types: [FETCH_SUPPLIERS, FETCH_SUPPLIERS_SUCCESS, FETCH_SUPPLIERS_FAILED],
		callApi: {
			endpoint: 'api/Suppliers',
			method: 'GET'
		}
	}
}

export const setValue = (name, value) => {
	return {
		type: SET_VALUE,
		name,
		value
	}
}

//Reducer
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_SUPPLIERS:
			return {
				...state,
				loading: true,
				error: false,
				suppliers: null
			}
		case FETCH_SUPPLIERS_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				suppliers: action.data
			}
		case FETCH_SUPPLIERS_FAILED:
			return {
				...state,
				loading: false,
				error: true,
				suppliers: null
			}
		case FETCH_INBOUND_DELIVERYS:
			return {
				...state,
				loading: true,
				completed: false,
				error: false,
				inboundDeliverys: null,
			}
		case FETCH_INBOUND_DELIVERYS_SUCCESS:
			return {
				...state,
				loading: false,
				completed: true,
				error: false,
				inboundDeliverys: action.data,
			}
		case FETCH_INBOUND_DELIVERYS_FAILED:
			return {
				...state,
				loading: false,
				completed: false,
				error: true,
				inboundDeliverys: null,
			}
		case SET_VALUE:
			return {
				...state,
				[action.name]: action.value
			}
		default:
			return state
	}
}
