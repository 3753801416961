import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Button, Card } from 'react-bootstrap'
import LoadingSpinner from 'components/LoadingSpinner'
import FloatingButtonWrapper from 'components/FloatingButtonWrapper'

import ConcurrencyNotification from 'components/ConcurrencyNotification'
import purchaseOrderDeliveryStatus, {
	getPurchaseOrderDeliveryStatus,
} from 'enums/purchaseOrderDeliveryStatus'
import InboundDeliveryRow from 'components/InboundDeliveryRow'
import PropTypes from 'prop-types'
import {
	fetchPurchaseOrderDelivery,
	savePurchaseOrderDelivery,
	completeOrderDelivery,
	setPayload,
	resetErrorMessage,
	deleteInboundOrderRow
} from './duck'

import './style.css'

const InboundOrder = ({ match }) => {
	const loading = useSelector((state) => state.inboundOrder.loading)
	const inboundOrder = useSelector((state) => state.inboundOrder.inboundOrder)
	const errorMessage = useSelector((state) => state.inboundOrder.errorMessage)

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { sequenceNumber } = useParams()

	const onChange = (name, value, rowId) => {
		dispatch(setPayload(rowId, name, value))
	}

	const updateDeliverys = async () => {
		const payload = {
			sequenceNumber: inboundOrder.sequenceNumber,
			lastChanged: inboundOrder.lastChanged,
			rows: inboundOrder.rows.map((row) => ({
				Id: row.id,
				QuantityDelivered: row.quantityDelivered,
				StockLocation: row.stockLocation,
				DotYear: row.dotYear,
				Weight: row.weight,
			})),
		}

		await dispatch(savePurchaseOrderDelivery({ payload }))

		dispatch(fetchPurchaseOrderDelivery(payload.sequenceNumber))
	}

	const completePurchaseOrderDelivery = async () => {
		const payload = {
			sequenceNumber: inboundOrder.sequenceNumber,
			lastChanged: inboundOrder.lastChanged,
		}
		await dispatch(completeOrderDelivery({ payload }))
		dispatch(fetchPurchaseOrderDelivery(payload.sequenceNumber))
	}

	const onCloseModal = () => {
		dispatch(resetErrorMessage())
	}

	const goHome = () => {
		navigate('/')
	}

	const onGoBack = () => {
		navigate(-1)
	}

	const onDeleteRow = (rowId) => {
		dispatch(deleteInboundOrderRow(sequenceNumber, rowId))
	}

	useEffect(() => {
		dispatch(fetchPurchaseOrderDelivery(sequenceNumber))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const loadingSpinner = loading ? <LoadingSpinner /> : ''

	return (
		<>
			<Container className="inboundOrder">
				{loadingSpinner}
				<Row className="justify-content-center">
					<Col xs={12} sm={12}>
						<Row className="justify-content-center">
							{inboundOrder && (
								<Card>
									<Card.Body>
										<div className="item row">
											{'Sequencenumber:'}
											<div className="info">{inboundOrder.sequenceNumber}</div>
										</div>
										<div className="item row">
											{'Waybillnumber:'}
											<div className="info">{inboundOrder.waybillNumber}</div>
										</div>
										<div className="item row">
											{'Suppliername:'}
											<div className="info">{inboundOrder.supplierName}</div>
										</div>
										<div className="item row">
											{'Status:'}
											<div className="info">
												{getPurchaseOrderDeliveryStatus(inboundOrder.status)}
											</div>
										</div>
										{
											inboundOrder.status ===
											purchaseOrderDeliveryStatus.ready &&
											(
												<Button
													variant="success"
													size="sm"
													onClick={completePurchaseOrderDelivery}
												>
													Set as delivered
												</Button>
											)}
										{inboundOrder.rows?.map((item) => (
											<div key={item.id} className="row-card">
												<InboundDeliveryRow
													row={item}
													disabled={inboundOrder.status === purchaseOrderDeliveryStatus.delivered}
													onChange={onChange}
													deleteRow={onDeleteRow}
												/>
											</div>
										))}
									</Card.Body>
								</Card>
							)}
						</Row>
					</Col>
				</Row>
				{errorMessage === '409' && (
					<ConcurrencyNotification onClose={onCloseModal} />
				)}
			</Container>
			<FloatingButtonWrapper>
				<Button variant="success" size="lg" onClick={updateDeliverys}>Save</Button>
				<Button variant="info" size="lg" onClick={onGoBack}>Back</Button>
				<Button variant="danger" size="lg" onClick={goHome}>Home</Button>
			</FloatingButtonWrapper>
		</>
	)
}

InboundOrder.propTypes = {
	match: PropTypes.object,
}

export default InboundOrder
