import React from 'react'
import PropTypes from 'prop-types'
import Numpad from 'components/Numpad'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import SvgBarcodes from 'assets/svgs/svgr/svgr/svgr/Barcodes'
import BarCodeScanner from 'features/BarCodeScanner'
import { useDispatch, useSelector } from 'react-redux'
import { toggleShowScanner } from 'features/BarCodeScanner/duck'

import './style.css'

const FloatingNumpad = ({
	headerText,
	text,
	showStockButton,
	hideExtraButton,
	onChange,
	onSend,
	onClear,
}) => {
	const showScanner = useSelector((state) => state.barCodeScanner.showScanner)
	const dispatch = useDispatch()

	const onClicked = (value, keyCode) => {
		let newValue

		if (keyCode === 8) {
			newValue = text.slice(0, text.length - 1)
		} else if (value === null) {
			return
		} else {
			newValue = text + value
		}

		onChange(newValue)
	}

	const onNewScanResult = (scannedValue) => {
		onClicked(scannedValue)
	}

	const toggleScanner = () => {
		dispatch(toggleShowScanner())
	}


	return (
		<Form className="floatingNumpad" onSubmit={onSend}>
			<div className="content">
				{headerText && <h4>{headerText}</h4>}
				<div className="input-group-append">
					<Form.Control
						type="text"
						name="stockLocation"
						readOnly
						value={text}
					/>
					<button
						className="btn btn-outline-secondary"
						type="button"
						id={'switchInputType'}
						onClick={toggleScanner}
					>
						<SvgBarcodes />
					</button>
				</div>

				<Numpad
					onClick={onClicked}
					onClear={onClear}
					onSend={onSend}
					showStockButton={showStockButton}
					hideExtraButton={hideExtraButton}
					buttonText="OK"
				/>
				<Button
					variant="danger"
					className="closeButton"
					size="lg"
					onClick={onClear}
				>
					Clear
				</Button>
			</div>
			{showScanner && (
				<BarCodeScanner
					onScannedResult={onNewScanResult}
					onClose={toggleScanner}
				/>
			)}
		</Form>
	)
}

FloatingNumpad.propTypes = {
	text: PropTypes.string.isRequired,
	showStockButton: PropTypes.bool.isRequired,
	hideExtraButton: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	onSend: PropTypes.func.isRequired,
	onClear: PropTypes.func.isRequired,
}

export default FloatingNumpad
