import React, { useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Form from 'react-bootstrap/Form'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Numpad from 'components/Numpad'
import TyreDimension from 'components/TyreDimension'
import If from 'components/If'
import Button from 'react-bootstrap/Button'
import LoadingOverlay from 'components/LoadingOverlay'
import Alert from 'react-bootstrap/Alert'
import ArticleTypeButtons from 'components/ArticleTypeButtons'
import TruckDimension from 'features/TruckDimension'
import LoaderDimension from 'features/LoaderDimension'
import RimDimension from 'components/RimDimension'
import TruckRimDimension from 'features/TruckRimDimension'
import BarCodeScanner from 'features/BarCodeScanner'
import articleType from 'enums/articleType'
import SvgKeyboard from 'assets/svgs/svgr/svgr/svgr/Keyboard'
import SvgBarcodes from 'assets/svgs/svgr/svgr/svgr/Barcodes'
import { useNavigate } from 'react-router-dom/dist'
import { usePrevious } from 'lib/utils'
import { toggleShowScanner } from 'features/BarCodeScanner/duck'

import {
	fetchBrands,
	fetchArticles,
	itemChange,
	dimensionChange,
	clearSearch,
	toggleSearchType,
	setDisableNumpad,
	fetchArticlesBySearchText,
	changeArticleType,
} from './duck'

import './style.css'

const ArticleSearch = () => {
	const dispatch = useDispatch()
	const {
		loading,
		brands,
		showNumpad,
		articles,
		showBrands,
		brandId,
		modelId,
		width,
		aspectRatio,
		diameter,
		bolts,
		circle,
		searchArticleId,
		articleId,
		inchSearch,
		selectedArticleType,
		truckDimension,
		loaderDimension,
		truckRimDimension,
		disableNumpad
	} = useSelector(state => state.articleSearch)

	const inputField = useRef(null)
	const navigate = useNavigate()

	const prevLoading = usePrevious(loading)
	const prevDisableNumpad = usePrevious(disableNumpad)

	const showScanner = useSelector((state) => state.barCodeScanner.showScanner)

	const onClickNumber = (value, keyCode) => {
		if (searchArticleId) {
			setArticleNumber(value, keyCode)
		}
	}

	const onDimensionChange = payload => {
		const { width, aspectRatio, diameter, bolts, circle, inchSearch } = payload
		dispatch(dimensionChange(width, aspectRatio, diameter, bolts, circle, inchSearch))
	}

	const onChange = e => {
		const { name, value } = e.target
		dispatch(itemChange(name, Number(value)))
	}

	const onChangeId = e => {
		const { name, value } = e.target
		dispatch(itemChange(name, value))
	}

	const onPresetDimensionChange = e => {
		const { name, value } = e.target
		dispatch(itemChange(name, value))
		const width = value.split(';')[0].replace(',', '.')
		const diameter = value.split(';')[2].replace(',', '.')
		let aspectRatio = value.split(';')[1].replace(',', '.')
		if (aspectRatio === '0') aspectRatio = ''
		dispatch(dimensionChange(width, aspectRatio, diameter, false))
	}

	const onTruckRimDimensionChange = e => {
		const { name, value } = e.target
		dispatch(itemChange(name, value))
		const width = value.split(';')[0].replace(',', '.')
		const diameter = value.split(';')[1].replace(',', '.')
		dispatch(dimensionChange(width, null, diameter, false))
	}

	const setArticleNumber = (value, keyCode) => {
		if (!value && keyCode !== 8) return
		if (keyCode === 8) {
			dispatch(itemChange('articleId', articleId.slice(0, -1)))
		} else {
			const newValue = articleId + value
			dispatch(itemChange('articleId', newValue))
		}
	}

	const onSubmit = (e) => {
		if (e)
			e.preventDefault()

		if (searchArticleId) {
			dispatch(fetchArticlesBySearchText(articleId.toString()))
		} else if (modelId && brandId) {
			dispatch(fetchArticles(width, aspectRatio, diameter, bolts, circle, brandId, modelId, selectedArticleType))
		} else {
			dispatch(fetchBrands(width, aspectRatio, diameter, bolts, circle, selectedArticleType))
		}
	}

	const onKeyboardBtnClick = () => {
		dispatch(setDisableNumpad(!disableNumpad))
		onClear()
	}

	const onClear = () => {
		dispatch(clearSearch())
	}

	const goBack = () => {
		dispatch(clearSearch())
		navigate(-1)
	}

	const toggleType = () => {
		onClear()
		dispatch(toggleSearchType())
	}

	const toggleScanner = () => {
		dispatch(toggleShowScanner())
	}

	const onNewScanResult = (decodedText, decodedResult) => {
		dispatch(itemChange('articleId', decodedText))
	}

	const onChangeArticleType = type => {
		const disableNumpad = type !== articleType.tyre && type !== articleType.rim && type !== articleType.MOTO
		dispatch(clearSearch())
		dispatch(changeArticleType(type, disableNumpad))
	}

	useEffect(() => {
		if (!loading && articles?.length) {
			if (prevLoading && articles.length) navigate('/articlelist/article/')
		}
		if (!prevDisableNumpad && disableNumpad && document.activeElement.id === 'switchInputType') {
			inputField.current.focus()
		}
	}, [loading, articles, prevLoading, prevDisableNumpad, disableNumpad, navigate])

	return (
		<Form onSubmit={onSubmit} className="articleSearch">
			<ArticleTypeButtons onClick={onChangeArticleType} active={selectedArticleType} />
			<div className="dimensionWrapper">
				<If condition={searchArticleId}>
					<div className="input-group mb-3">
						<input
							className="form-control"
							type="text"
							ref={inputField}
							disabled={!disableNumpad}
							name="articleId"
							value={articleId}
							onChange={onChangeId}
							placeholder="Article number / EAN / SupplierId"
							aria-describedby="basic-addon2"
						/>
						<div className="input-group-append">
							<button className="btn btn-outline-secondary" type="button" id={'switchInputType'} onClick={onKeyboardBtnClick}>
								<SvgKeyboard />
							</button>
							<button className="btn btn-outline-secondary" type="button" id={'switchInputType'} onClick={toggleScanner}>
								<SvgBarcodes />
							</button>
						</div>
					</div>
				</If>

				<If condition={!searchArticleId && selectedArticleType === articleType.tyre}>
					<TyreDimension
						onChange={onDimensionChange}
						width={width}
						aspectRatio={aspectRatio}
						diameter={diameter}
						inchSearch={inchSearch}
						showNumpad={showNumpad}
						onSubmit={onSubmit}
					/>
				</If>
				<If condition={!searchArticleId && selectedArticleType === articleType.truckTyre}>
					<TruckDimension
						disabled={!showNumpad}
						onChange={onPresetDimensionChange}
						selectedItem={truckDimension} />
				</If>
				<If condition={!searchArticleId && selectedArticleType === articleType.loadTyre}>
					<LoaderDimension
						disabled={!showNumpad}
						onChange={onPresetDimensionChange}
						selectedItem={loaderDimension} />
				</If>
				<If condition={!searchArticleId && selectedArticleType === articleType.rim}>
					<RimDimension
						onChange={onDimensionChange}
						diameter={diameter}
						width={width}
						bolts={bolts}
						circle={circle}
						inchSearch={inchSearch}
						showNumpad={showNumpad}
						onSubmit={onSubmit}
					/>
				</If>
				<If condition={!searchArticleId && selectedArticleType === articleType.truckRims}>
					<TruckRimDimension
						disabled={!showNumpad}
						onChange={onTruckRimDimensionChange}
						selectedItem={truckRimDimension} />
				</If>
				<If condition={!searchArticleId && selectedArticleType === articleType.MOTO}>
					<TyreDimension
						onChange={onDimensionChange}
						width={width}
						aspectRatio={aspectRatio}
						diameter={diameter}
						showNumpad={showNumpad}
						onSubmit={onSubmit} />
				</If>
			</div>
			<If condition={!showNumpad}>
				<Form.Group>
					<Form.Control
						disabled={!brands}
						name="brandId"
						onChange={onChange}
						value={brandId} as="select"
						size="lg"
					>
						<option value="0">- Select brand -</option>
						{brands ? brands.map(item => (<option key={item.id} value={item.id}>{item.name}</option>)) : ''}
					</Form.Control>
				</Form.Group>
				<Form.Group>
					<Form.Control
						disabled={!brandId}
						name="modelId"
						onChange={onChange}
						value={modelId}
						as="select"
						size="lg"
					>
						<option value="0">- Select pattern -</option>
						{brandId ? brands.find(b => b.id === brandId).patternModels.map(item => (<option key={item.id} value={item.id}>{item.name}</option>)) : ''}
					</Form.Control>
				</Form.Group>
			</If>
			<If condition={disableNumpad || searchArticleId}>
				<Form.Group>
					<Numpad
						onClick={onClickNumber}
						onSend={onSubmit}
						showStockButton={false}
						allowCharacters={disableNumpad}
						buttonText="Search"
						hideExtraButton={searchArticleId}
						disabled={disableNumpad}
					/>
				</Form.Group>
			</If>
			<If condition={showBrands}>
				<Form.Group>
					<Button variant="success" type="submit" size="lg">Search</Button>
				</Form.Group>
			</If>
			{articles && !articles.length && !loading ? (<Alert variant="danger">No articles found</Alert>) : ''}
			<If condition={!showBrands}>
				<Form.Group>
					<Button variant="info" type="button" onClick={toggleType} size="lg">{searchArticleId ? 'Dimension' : 'Article number / EAN / Supplier Id'}</Button>
				</Form.Group>
			</If>
			<Form.Group>
				<ButtonGroup size="lg">
					<Button variant="danger" onClick={goBack}>Home</Button>
					<Button variant="outline-dark" onClick={onClear}>Clear</Button>
				</ButtonGroup>
			</Form.Group>
			{loading ? <LoadingOverlay /> : ''}
			{showScanner && (<BarCodeScanner onScannedResult={onNewScanResult} />)}
		</Form>
	)
}

export default ArticleSearch
