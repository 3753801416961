const initialState = {
	loading: false,
	inboundOrder: null,
	error: false,
	errorMessage: '',
}

const FETCH_INBOUND_ORDER = 'features/InboundOrder/FETCH_INBOUND_ORDER'
const FETCH_INBOUND_ORDER_SUCCESS =
	'features/InboundOrder/FETCH_INBOUND_ORDER_SUCCESS'
const FETCH_INBOUND_ORDER_FAILED =
	'features/InboundOrder/FETCH_INBOUND_ORDER_FAILED'

const SAVE_INBOUND_ORDER = 'features/InboundOrder/SAVE_INBOUND_ORDER'
const SAVE_INBOUND_ORDER_SUCCESS =
	'features/InboundOrder/SAVE_INBOUND_ORDER_SUCCESS'
const SAVE_INBOUND_ORDER_FAILED =
	'features/InboundOrder/SAVE_INBOUND_ORDER_FAILED'

const COMPLETE_INBOUND_ORDER = 'features/InboundOrder/SAVE_INBOUND_ORDER'
const COMPLETE_INBOUND_ORDER_SUCCESS =
	'features/InboundOrder/SAVE_INBOUND_ORDER_SUCCESS'
const COMPLETE_INBOUND_ORDER_FAILED =
	'features/InboundOrder/SAVE_INBOUND_ORDER_FAILED'

const DELETE_INBOUND_ORDER_ROW = 'features/InboundOrder/DELETE_INBOUND_ORDER_ROW'
const DELETE_INBOUND_ORDER_ROW_SUCCESS = 'features/InboundOrder/DELETE_INBOUND_ORDER_ROW_SUCCESS'
const DELETE_INBOUND_ORDER_ROW_FAILED = 'features/InboundOrder/DELETE_INBOUND_ORDER_ROW_FAILED'

const SET_ROW = 'features/InboundOrder/SET_ROW'
const RESET_ERROR_MESSAGE = 'features/InboundOrder/RESET_ERROR_MESSAGE'

//Actions
export const fetchPurchaseOrderDelivery = (sequenceNumber) => {
	const data = {
		sequenceNumber,
	}

	return {
		types: [
			FETCH_INBOUND_ORDER,
			FETCH_INBOUND_ORDER_SUCCESS,
			FETCH_INBOUND_ORDER_FAILED,
		],
		callApi: {
			endpoint: 'api/PurchaseOrderDelivery',
			method: 'GET',
			data: data,
		},
	}
}

export const deleteInboundOrderRow = (sequenceNumber, rowId) => {
	const data = {
		sequenceNumber,
		rowId,
	}

	return {
		types: [
			DELETE_INBOUND_ORDER_ROW,
			DELETE_INBOUND_ORDER_ROW_SUCCESS,
			DELETE_INBOUND_ORDER_ROW_FAILED,
		],
		callApi: {
			endpoint: 'api/PurchaseOrderDelivery',
			method: 'DELETE',
			data: data,
		},
	}
}

export const setPayload = (rowId, name, value) => {
	return {
		type: SET_ROW,
		data: { rowId, name, value },
	}
}

export const resetErrorMessage = () => {
	return {
		type: RESET_ERROR_MESSAGE,
	}
}

export const savePurchaseOrderDelivery = ({ payload }) => {
	const data = {
		sequenceNumber: payload.sequenceNumber,
		lastChanged: payload.lastChanged,
		rows: payload.rows,
	}

	return {
		types: [
			SAVE_INBOUND_ORDER,
			SAVE_INBOUND_ORDER_SUCCESS,
			SAVE_INBOUND_ORDER_FAILED,
		],
		callApi: {
			endpoint: 'api/PurchaseOrderDelivery',
			method: 'PUT',
			data: data,
		},
	}
}

export const completeOrderDelivery = ({ payload }) => {
	const data = {
		sequenceNumber: payload.sequenceNumber,
		lastChanged: payload.lastChanged,
	}

	return {
		types: [
			COMPLETE_INBOUND_ORDER,
			COMPLETE_INBOUND_ORDER_SUCCESS,
			COMPLETE_INBOUND_ORDER_FAILED,
		],
		callApi: {
			endpoint: 'api/PurchaseOrderDelivery',
			method: 'POST',
			data: data,
		},
	}
}

//Reducer
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_INBOUND_ORDER:
			return {
				...state,
				loading: true,
				inboundOrder: null,
			}
		case FETCH_INBOUND_ORDER_SUCCESS:
			return {
				...state,
				loading: false,
				inboundOrder: action.data,
			}
		case FETCH_INBOUND_ORDER_FAILED:
			return {
				...state,
				loading: false,
				inboundOrder: null,
			}

		case SAVE_INBOUND_ORDER:
			return {
				...state,
				loading: true,
			}
		case SAVE_INBOUND_ORDER_SUCCESS:
			return {
				...state,
				loading: false,
			}
		case SAVE_INBOUND_ORDER_FAILED:
			return {
				...state,
				loading: false,
				error: true,
				errorMessage: action.message,
			}

		case COMPLETE_INBOUND_ORDER:
			return {
				...state,
				loading: true,
			}
		case COMPLETE_INBOUND_ORDER_SUCCESS:
			return {
				...state,
				loading: false,
			}
		case COMPLETE_INBOUND_ORDER_FAILED:
			return {
				...state,
				loading: false,
				error: true,
			}
		case DELETE_INBOUND_ORDER_ROW:
			return {
				...state,
				loading: true,
			}
		case DELETE_INBOUND_ORDER_ROW_SUCCESS:
			return {
				...state,
				loading: false,
				inboundOrder: action.data
			}
		case DELETE_INBOUND_ORDER_ROW_FAILED:
			return {
				...state,
				loading: false,
				error: true,
				errorMessage: action.message,
			}
		case SET_ROW:
			return {
				...state,
				inboundOrder: {
					...state.inboundOrder,
					rows: state.inboundOrder.rows.map((row) => {
						if (row.id === action.data.rowId) {
							return {
								...row,
								[action.data.name]: action.data.value,
							}
						}
						return row
					}),
				},
			}
		case RESET_ERROR_MESSAGE:
			return {
				...state,
				error: false,
				errorMessage: '',
			}

		default:
			return state
	}
}
