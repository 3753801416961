const initialState = {
	loading: false,
	createdSequenceNumber: null,
	stockTakingList: null,
	articles: null,
	processed: false,
	isDirty: false,
}

const FETCH_STOCKTAKING = 'features/StockTaking/FETCH_STOCKTAKING'
const FETCH_STOCKTAKING_SUCCESS =
	'features/StockTaking/FETCH_STOCKTAKING_SUCCESS'
const FETCH_STOCKTAKING_FAILED = 'features/StockTaking/FETCH_STOCKTAKING_FAILED'

const CREATE_NEW_STOCKTAKING = 'features/StockTaking/CREATE_NEW_STOCKTAKING'
const CREATE_NEW_STOCKTAKING_SUCCESS =
	'features/StockTaking/CREATE_NEW_STOCKTAKING_SUCCESS'
const CREATE_NEW_STOCKTAKING_FAILED =
	'features/StockTaking/CREATE_NEW_STOCKTAKING_FAILED'

const SAVE_NEW_STOCKTAKING = 'features/StockTaking/SAVE_NEW_STOCKTAKING'
const SAVE_NEW_STOCKTAKING_SUCCESS =
	'features/StockTaking/SAVE_NEW_STOCKTAKING_SUCCESS'
const SAVE_NEW_STOCKTAKING_FAILED =
	'features/StockTaking/SAVE_NEW_STOCKTAKING_FAILED'

const PROCESS_STOCKTAKING = 'features/StockTaking/PROCESS_STOCKTAKING'
const PROCESS_STOCKTAKING_SUCCESS =
	'features/StockTaking/PROCESS_STOCKTAKING_SUCCESS'
const PROCESS_STOCKTAKING_FAILED =
	'features/StockTaking/PROCESS_STOCKTAKING_FAILED'

const UPDATE_STOCKTAKING_ROW = 'features/StockTaking/UPDATE_STOCKTAKING_ROW'

//Actions
export const fetchStockTaking = (id) => {
	return {
		types: [
			FETCH_STOCKTAKING,
			FETCH_STOCKTAKING_SUCCESS,
			FETCH_STOCKTAKING_FAILED,
		],
		callApi: {
			endpoint: 'api/stockTaking/' + id,
			method: 'GET',
		},
	}
}

export const createNewStockTaking = (payload) => {
	return {
		types: [
			CREATE_NEW_STOCKTAKING,
			CREATE_NEW_STOCKTAKING_SUCCESS,
			CREATE_NEW_STOCKTAKING_FAILED,
		],
		callApi: {
			endpoint: 'api/stockTaking/',
			method: 'POST',
			data: payload,
		},
	}
}

export const saveNewStockTaking = (payload) => {
	return {
		types: [
			SAVE_NEW_STOCKTAKING,
			SAVE_NEW_STOCKTAKING_SUCCESS,
			SAVE_NEW_STOCKTAKING_FAILED,
		],
		callApi: {
			endpoint: 'api/stockTaking/',
			method: 'PUT',
			data: payload,
		},
	}
}

export const processStockTaking = (payload) => {
	return {
		types: [
			PROCESS_STOCKTAKING,
			PROCESS_STOCKTAKING_SUCCESS,
			PROCESS_STOCKTAKING_FAILED,
		],
		callApi: {
			endpoint: 'api/stockTakingProcess',
			method: 'POST',
			data: payload,
		},
	}
}

export const updateStockTakingRow = (payload) => {
	return {
		type: UPDATE_STOCKTAKING_ROW,
		rowId: payload.rowId,
		name: payload.name,
		value: payload.value,
	}
}

//Reducer
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_STOCKTAKING:
			return {
				...state,
				loading: true,
				stockTakingList: null,
				completed: false,
			}
		case FETCH_STOCKTAKING_SUCCESS:
			return {
				...state,
				loading: false,
				stockTakingList: action.data,
				isDirty: false,
				completed: true,
				processed: false,
			}
		case FETCH_STOCKTAKING_FAILED:
			return {
				...state,
				loading: false,
			}
		case CREATE_NEW_STOCKTAKING:
			return {
				...state,
				loading: true,
				createdSequenceNumber: null,
			}
		case CREATE_NEW_STOCKTAKING_SUCCESS:
			return {
				...state,
				loading: false,
				createdSequenceNumber: action.data,
			}
		case CREATE_NEW_STOCKTAKING_FAILED:
			return {
				...state,
				loading: false,
			}
		case SAVE_NEW_STOCKTAKING:
			return {
				...state,
				loading: true,
				stockTakingList: null,
			}
		case SAVE_NEW_STOCKTAKING_SUCCESS:
			return {
				...state,
				loading: false,
				isDirty: false,
				stockTakingList: action.data,
			}
		case SAVE_NEW_STOCKTAKING_FAILED:
			return {
				...state,
				loading: false,
			}
		case UPDATE_STOCKTAKING_ROW:
			// update property for row based on id {rowId, name, value}
			return {
				...state,
				isDirty: true,
				stockTakingList: {
					...state.stockTakingList,
					rows: state.stockTakingList.rows.map((item) => {
						if (item.id === action.rowId) {
							return {
								...item,
								[action.name]: action.value,
							}
						}
						return item
					}),
				},
			}
		case PROCESS_STOCKTAKING:
			return {
				...state,
				loading: true,
			}
		case PROCESS_STOCKTAKING_SUCCESS:
			return {
				...state,
				loading: false,
				processed: true,
			}
		case PROCESS_STOCKTAKING_FAILED:
			return {
				...state,
				loading: false,
			}
		default:
			return state
	}
}
