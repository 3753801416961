import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Button } from 'react-bootstrap'
import ArticleTitle from 'components/ArticleTitle'
import ArticleLocationInfo from 'components/ArticleLocationInfo'
import ArticleIdInfo from 'components/ArticleIdInfo'
import LoadingSpinner from 'components/LoadingSpinner'
import barcodes from '../ArticleSearch/assets/barcodes.svg'
import BarCodeScanner from 'features/BarCodeScanner'
import FloatingButtonWrapper from 'components/FloatingButtonWrapper'
import { toggleShowScanner } from 'features/BarCodeScanner/duck'
import ArticleStockLocationAdd from 'features/ArticleStockLocationAdd'
import { useNavigate, useParams } from 'react-router'


import { fetchArticles, fetchArticlesById } from './duck'

import './style.css'

const ArticleStockLocationList = () => {
	const dispatch = useDispatch()
	const articles = useSelector(
		(state) => state.articleStockLocationList.articles,
	)
	const loading = useSelector((state) => state.articleStockLocationList.loading)
	const showScanner = useSelector((state) => state.barCodeScanner.showScanner)
	const [newLocation, setNewLocation] = useState(null)
	const [showAddArticle, setShowAddArticle] = useState(false)
	const [findLocation, setFindLocation] = useState(false)
	const { stockLocation, articleId } = useParams()

	const navigate = useNavigate()
	useEffect(() => {
		if (stockLocation) {
			dispatch(fetchArticles(articleId, stockLocation))
		} else {
			dispatch(fetchArticlesById(articleId))
		}
	}, [dispatch, stockLocation, articleId])

	const onNewScanResult = (location) => {
		const article = articles.find((item) => item.location === Number(location))
		onArticleClicked(article)

		if (!article) {
			setNewLocation(location)
			setFindLocation(true)
		}
	}

	const toggleScanner = () => {
		dispatch(toggleShowScanner())
	}

	const toggleAddArticle = () => {
		setShowAddArticle(!showAddArticle)
	}

	const onArticleClicked = (article) => {
		if (article) {
			navigate(`/article/${article.articleId}/${article.locationString}/${article.dotYear}`,
			)
		}
	}

	const loadingSpinner = loading ? <LoadingSpinner /> : null

	return (
		<>
			<Container className="articleStockLocationList">
				{loadingSpinner}
				<Row className="justify-content-center">
					<Col xs={12} sm={12}>
						<Row className="justify-content-center">
							{articles?.length
								? articles.map((item) => (
									<Card
										key={item.locationString + '-' + item.dotYear}
										onClick={() => {
											onArticleClicked(item)
										}}
									>
										<Card.Body>
											<ArticleTitle item={item} />
											<ArticleLocationInfo
												location={item.locationText}
												quantity={item.quantityInStock}
											/>
											<ArticleIdInfo
												articleId={item.articleId}
												supplierArticleId={item.supplierArticleId}
											/>
										</Card.Body>
									</Card>
								))
								: null}
						</Row>
					</Col>
				</Row>
				{showScanner && <BarCodeScanner onScannedResult={onNewScanResult} />}
			</Container>
			<FloatingButtonWrapper showHome={true}>
				{findLocation && (
					<Button variant="primary" size="lg" onClick={toggleAddArticle}>
						Add article to location
					</Button>
				)}
				<Button variant="secondary" size="lg" onClick={toggleScanner}>
					<img src={barcodes} alt="barcode input" />
				</Button>
			</FloatingButtonWrapper>
			{showAddArticle && articles?.length && (
				<ArticleStockLocationAdd
					articleId={articleId}
					newLocation={newLocation}
					onClose={toggleAddArticle}
				/>
			)}
		</>
	)
}

export default ArticleStockLocationList
