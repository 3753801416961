import './style.css'
import FloatingButtonWrapper from 'components/FloatingButtonWrapper'
import React, {useCallback, useEffect, useRef} from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import LoadingOverlay from 'components/LoadingOverlay'
import CardInfo from 'components/CardInfo/CardInfo'
import dayjs from 'dayjs'
import classNames from 'classnames'
import useStockTakingCreator from 'lib/hooks/useStockTakingCreator'
import PropTypes from 'prop-types'
import FloatingFilterButton from 'components/FloatingFilterButton'
import FloatingWindow from 'components/FloatingWindow'
import If from 'components/If'
import RollingInventorySearchForm from 'components/RollingInventorySearchForm'

import { fetchFilter, fetchLocations, setValue } from './duck'
import { showMessage } from '../MessageBox/duck'

const RollingInventoryRow = ({ item, onClick, isChecked, className }) => {
	const settings = useSelector((state) => state.app.settings)

	const currentYear = new Date().getFullYear().toString()
	const rollingYear = dayjs(item.lastChanged).format('YYYY')
	let locationTextColor

	if (rollingYear === currentYear) {
		locationTextColor = 'greenText'
	} else {
		locationTextColor = 'redText'
	}

	return (
		<Card
			onClick={() => onClick(item.stockLocation)}
			className={classNames('rolling-card', { rowChecked: isChecked }, className)}
		>
			<Card.Body>
				<CardInfo
					items={[
						{
							label: 'Location',
							value: item.formattedStockLocation,
							class: locationTextColor,
						},
						{
							label: 'Last review',
							value: dayjs(item.lastChanged).format(settings.shortDateFormat),
						},
					]}
				/>
			</Card.Body>
		</Card>
	)
}

const RollingInventory = () => {
	const dispatch = useDispatch()
	const locations = useSelector((state) => state.rollingInventory.locations)
	const loading = useSelector((state) => state.rollingInventory.loading)

	const userWarehouseId = useSelector((state) => state.login.user?.warehouseId)

	const [selectedLocations, setSelectedLocations] = React.useState([])
	const { loadingStockTaking, createStockTakingByLocations } = useStockTakingCreator()

	const selectedWarehouse = useSelector((state) => state.rollingInventory.selectedWarehouse)
	const selectedHall = useSelector((state) => state.rollingInventory.selectedHall)
	const selectedAisle = useSelector((state) => state.rollingInventory.selectedAisle)
	const selectedPlace = useSelector((state) => state.rollingInventory.selectedPlace)
	const selectedHeight = useSelector((state) => state.rollingInventory.selectedHeight)

	const sortType = useSelector((state) => state.rollingInventory.sortType)

	const warehouseFilterRef = useSelector((state) => state.rollingInventory.warehouseFilterRef)

	const [currentWarehouse, setCurrentWarehouse] = React.useState('')
	const [currentSortType, setCurrentSortType] = React.useState('1')
	const [currentHall, setCurrentHall] = React.useState('')
	const [currentAisle, setCurrentAisle] = React.useState('')
	const [currentPlace, setCurrentPlace] = React.useState('')
	const [currentHeight, setCurrentHeight] = React.useState('')

	const [filter, setFilter] = React.useState(false)
	const submit = useRef(false)

	const onToggleLocation = (stockLocation) => {
		if (selectedLocations.includes(stockLocation)) {
			setSelectedLocations(
				selectedLocations.filter((item) => item !== stockLocation),
			)
		} else {
			setSelectedLocations([...selectedLocations, stockLocation])
		}
	}

	const onFilterClicked = () => {
		submit.current = false
		onFilter()
	}

	const onFilter = useCallback(() => {
		setFilter(!filter)
	}, [filter, setFilter])

	const onSubmit = (e) => {
		if (e) e.preventDefault()
		submit.current = true

		if (selectedWarehouse !== currentWarehouse
			|| sortType !== currentSortType
			|| selectedHall !== currentHall
			|| selectedAisle !== currentAisle
			|| selectedPlace !== currentPlace
			|| selectedHeight !== currentHeight) {
			dispatch(fetchLocations({
				sortType: sortType,
				warehouseId: selectedWarehouse,
				hallNumber: selectedHall,
				aisleNumber: selectedAisle,
				placeNumber: selectedPlace,
				heightNumber: selectedHeight
			}))
			setCurrentWarehouse(selectedWarehouse)
			setCurrentSortType(sortType)
			setCurrentHall(selectedHall)
			setCurrentAisle(selectedAisle)
			setCurrentPlace(selectedPlace)
			setCurrentHeight(selectedHeight)
		}else{
			onFilter()
		}
	}

	const onChange = (name, value) => {
		dispatch(setValue(name, value))
	}

	const onClickCreate = () => {
		createStockTakingByLocations(selectedLocations)
	}

	useEffect(() => {
		if (userWarehouseId) setValue('selectedWarehouse', userWarehouseId)
	}, [userWarehouseId])

	useEffect(() => {
		dispatch(fetchLocations({ sortType: sortType, warehouseId: null }))
		dispatch(fetchFilter())

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if(filter && locations?.length > 0 && submit.current) {
			onFilter()
		}
	}, [filter, locations, onFilter])

	useEffect(() => {
		if(locations?.length === 0 && submit.current) {
			dispatch(showMessage('Info', 'No locations found'))
		}
	}, [locations, dispatch])

	return (
		<>
			<Container className="RollingInventory">
				{locations && !loading && (
					<Row className="">
						<Col xs={12} sm={12}>
							<Row className="">
								{locations.map((location) => (
									<RollingInventoryRow
										key={location.stockLocation}
										item={location}
										onClick={onToggleLocation}
										isChecked={selectedLocations.includes(
											location.stockLocation,
										)}
									/>
								))}
							</Row>
						</Col>
					</Row>
				)}
				{(loading || loadingStockTaking) && <LoadingOverlay />}
			</Container>

			<If condition={filter}>
				<FloatingWindow className="justify-content-center" closeOutSide={false} draggable={false} showHeader={true} fullSize={true}>
					<RollingInventorySearchForm
						onSubmit={onSubmit}
						onChange={onChange}
						selectedWarehouse={selectedWarehouse}
						userWarehouseId={userWarehouseId}
						selectedHall={selectedHall}
						selectedAisle={selectedAisle}
						selectedPlace={selectedPlace}
						selectedHeight={selectedHeight}
						sortType={sortType}
						warehouseFilterRef={warehouseFilterRef}
					/>
				</FloatingWindow>
			</If>

			<FloatingButtonWrapper showHome={true}>
				<FloatingFilterButton
					positionX='middle'
					positionY='bottom'
					btnState={filter}
					onClick={onFilterClicked}
					title='Filter and Search'
				/>
				{selectedLocations.length > 0 && (
					<Button variant="primary" size="lg" onClick={onClickCreate}>
						Create stock taking
					</Button>
				)}
			</FloatingButtonWrapper>
		</>
	)
}

RollingInventoryRow.propTypes = {
	item: PropTypes.object,
	onClick: PropTypes.func,
	isChecked: PropTypes.bool,
	className: PropTypes.string,
}

export default RollingInventory