const initialState = {
	loading: false,
	data: null,
}

const FETCH_WAREHOUSES = 'FETCH_WAREHOUSES'
const FETCH_WAREHOUSES_SUCCESS = 'FETCH_WAREHOUSES_SUCCESS'
const FETCH_WAREHOUSES_FAILED = 'FETCH_WAREHOUSES_FAILED'

export const fetchWarehouses = (payload) => ({
	types: [FETCH_WAREHOUSES, FETCH_WAREHOUSES_SUCCESS, FETCH_WAREHOUSES_FAILED],
	callApi: {
		endpoint: 'api/Warehouses',
		method: 'GET',
		data: payload,
	},
})

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_WAREHOUSES:
			return {
				...state,
				loading: true,
				data: null,
			}
		case FETCH_WAREHOUSES_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.data,
			}
		case FETCH_WAREHOUSES_FAILED:
			return {
				...state,
				loading: false,
			}
		default:
			return state
	}
}

export default reducer
