import mainGroups from 'enums/mainGroups'
import React from 'react'

export const format = (value, n, x, s, c) => {
	n = n === undefined ? 2 : n
	x = x === undefined ? 3 : x
	s = s === undefined ? ' ' : s
	c = c === undefined ? ',' : c

	var re = '\\d(?=(\\d{' + x + '})+' + (n > 0 ? '\\D' : '$') + ')',
		num = value.toFixed(Math.max(0, ~~n))

	return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + s)
}

export const getPrefixIfUserWarehouse = (user, location) => {
	var assignedWarehouseWithStartNumber

	if (user.warehouseInfo?.some((i) => i.locationStartNumber))
		assignedWarehouseWithStartNumber = user.warehouseInfo?.find(
			(i) => i.locationStartNumber !== null,
		)

	if (
		assignedWarehouseWithStartNumber &&
		location.length ===
			assignedWarehouseWithStartNumber.locationStart.toString().length -
				assignedWarehouseWithStartNumber.locationStartNumber.toString().length
	) {
		return assignedWarehouseWithStartNumber.locationStartNumber + location
	} else {
		return location
	}
}

export const isAccessory = (mainGroupId) => {
	const groups = [
		mainGroups.accessoriesCar,
		mainGroups.accessoriesIndustry,
		mainGroups.accessoriesBattery,
		mainGroups.accessoriesOil,
		mainGroups.tpmsPcr,
		mainGroups.tpmsTbr,
		mainGroups.tubes,
		mainGroups.tubesIndustry,
		mainGroups.tubesMoto,
	]
	return groups.includes(mainGroupId)
}

export const usePrevious = (value) => {
	const ref = React.useRef()
	React.useEffect(() => {
		ref.current = value
	})
	return ref.current
}
