import initialState from './initialState'

const POST_NEWSTOCKLOCATION = 'features/MoveArticle/POST_NEWSTOCKLOCATION'
const POST_NEWSTOCKLOCATION_SUCCESS =
	'features/MoveArticle/POST_NEWSTOCKLOCATION_SUCCESS'
const POST_NEWSTOCKLOCATION_FAILED =
	'features/MoveArticle/POST_NEWSTOCKLOCATION_FAILED'

const FETCH_ARTICLE = 'features/MoveArticle/FETCH_ARTICLE'
const FETCH_ARTICLE_SUCCESS = 'features/MoveArticle/FETCH_ARTICLE_SUCCESS'
const FETCH_ARTICLE_FAILED = 'features/MoveArticle/FETCH_ARTICLE_FAILED'

const FETCH_STOCK = 'features/MoveArticle/FETCH_STOCK'
const FETCH_STOCK_SUCCESS = 'features/MoveArticle/FETCH_STOCK_SUCCESS'
const FETCH_STOCK_FAILED = 'features/MoveArticle/FETCH_STOCK_FAILED'

const FETCH_VALID_LOCATION = 'features/MoveArticle/FETCH_VALID_LOCATION'
const FETCH_VALID_LOCATION_SUCCESS =
	'features/MoveArticle/FETCH_VALID_LOCATION_SUCCESS'
const FETCH_VALID_LOCATION_FAILED =
	'features/MoveArticle/FETCH_VALID_LOCATION_FAILED'

const RESET_VALID_LOCATION = 'features/MoveArticle/RESET_VALID_LOCATION'
const SET_VALUE = 'features/MoveArticle/SET_VALUE'

//Actions
export const fetchArticle = (articleId) => {
	return {
		types: [FETCH_ARTICLE, FETCH_ARTICLE_SUCCESS, FETCH_ARTICLE_FAILED],
		callApi: {
			endpoint: 'api/article/' + articleId,
			method: 'GET',
		},
	}
}

export const fetchStock = (articleId, stockLocation, dotYear) => {
	const payload = {
		articleId,
		stockLocation,
		dotYear,
	}

	return {
		types: [FETCH_STOCK, FETCH_STOCK_SUCCESS, FETCH_STOCK_FAILED],
		callApi: {
			data: payload,
			endpoint: 'api/stocklocation/',
			method: 'GET',
		},
	}
}

export const postStockLocation = (
	articleId,
	quantity,
	fromLocation,
	toLocation,
	dotYear,
) => {
	const payload = {
		articleId,
		quantity,
		fromLocation,
		toLocation,
		dotYear,
	}

	return {
		types: [
			POST_NEWSTOCKLOCATION,
			POST_NEWSTOCKLOCATION_SUCCESS,
			POST_NEWSTOCKLOCATION_FAILED,
		],
		callApi: {
			endpoint: 'api/StockRelocation/',
			method: 'POST',
			data: payload,
		},
	}
}

export const setValue = (name, value) => {
	return {
		type: SET_VALUE,
		name,
		value,
	}
}

export const validateStockLocation = (fromStockLocation, toStockLocation) => {
	const payload = {
		fromStockLocation,
		toStockLocation,
	}

	return {
		types: [
			FETCH_VALID_LOCATION,
			FETCH_VALID_LOCATION_SUCCESS,
			FETCH_VALID_LOCATION_FAILED,
		],
		callApi: {
			endpoint: 'api/StockLocationCompare',
			method: 'GET',
			data: payload,
		},
	}
}

export const resetValidateStockLocation = () => {
	return {
		type: RESET_VALID_LOCATION,
	}
}

//Reducer
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case POST_NEWSTOCKLOCATION:
			return {
				...state,
				completed: false,
				loading: true,
				stockLocationValid: null,
				error: false,
			}
		case POST_NEWSTOCKLOCATION_SUCCESS:
			return {
				...state,
				completed: true,
				moveQuantity: '',
				newLocation: '',
				loading: false,
				error: false,
			}
		case POST_NEWSTOCKLOCATION_FAILED:
			return {
				...state,
				loading: false,
				error: true,
			}
		case FETCH_ARTICLE:
			return {
				...state,
				loading: true,
				article: null,
			}
		case FETCH_ARTICLE_SUCCESS:
			return {
				...state,
				loading: false,
				article: action.data,
			}
		case FETCH_ARTICLE_FAILED:
			return {
				...state,
				loading: false,
				article: null,
			}
		case SET_VALUE:
			return {
				...state,
				stockLocationValid: null,
				[action.name]: action.value,
			}
		case FETCH_STOCK:
			return {
				...state,
				stockQuantity: null,
				completed: false,
				loading: true,
			}
		case FETCH_STOCK_SUCCESS:
			return {
				...state,
				stockInfo: action.data,
				loading: false,
			}
		case FETCH_STOCK_FAILED:
			return {
				...state,
				stockInfo: null,
				loading: false,
			}
		case FETCH_VALID_LOCATION:
			return {
				...state,
				loading: true,
				stockLocationValid: null,
			}
		case FETCH_VALID_LOCATION_SUCCESS:
			return {
				...state,
				loading: false,
				stockLocationValid: action.data,
			}
		case FETCH_VALID_LOCATION_FAILED:
			return {
				...state,
				loading: false,
				stockLocationValid: false,
			}
		case RESET_VALID_LOCATION:
			return {
				...state,
				stockLocationValid: null,
			}
		default:
			return state
	}
}
