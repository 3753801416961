import initialState from './initialState'

const FETCH_STOCKARTICLES = 'features/StockLocationSearch/FETCH_STOCKARTICLES'
const FETCH_STOCKARTICLES_SUCCESS =
	'features/StockLocationSearch/FETCH_STOCKARTICLES_SUCCESS'
const FETCH_STOCKARTICLES_FAILED =
	'features/StockLocationSearch/FETCH_STOCKARTICLES_FAILED'

const SET_LOCATION = 'features/StockLocationSearch/SET_LOCATION'
const RESET_LOCATION = 'features/StockLocationSearch/RESET_LOCATION'

//Actions
export const fetchStockArticles = (stockLocation) => {
	return {
		types: [
			FETCH_STOCKARTICLES,
			FETCH_STOCKARTICLES_SUCCESS,
			FETCH_STOCKARTICLES_FAILED,
		],
		callApi: {
			endpoint: 'api/stockLocationSearch/' + stockLocation,
			method: 'GET',
		},
	}
}

export const setLocation = (location) => {
	return {
		type: SET_LOCATION,
		data: location,
	}
}

export const resetLocation = () => {
	return {
		type: RESET_LOCATION,
	}
}

//Reducer
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_STOCKARTICLES:
			return {
				...state,
				loading: true,
				articles: null,
				completed: false,
			}
		case FETCH_STOCKARTICLES_SUCCESS:
			return {
				...state,
				loading: false,
				articles: action.data,
				completed: true,
			}
		case FETCH_STOCKARTICLES_FAILED:
			return {
				...state,
				loading: false,
			}
		case SET_LOCATION:
			return {
				...state,
				location: action.data,
			}
		case RESET_LOCATION:
			return {
				...state,
				location: '',
			}
		default:
			return state
	}
}
