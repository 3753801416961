import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import { connect } from 'react-redux'

import { fetchDimensions } from './duck'

import './style.css'

export class LoaderDimension extends Component {
	static propTypes = {
		selectedItem: PropTypes.string,
		onChange: PropTypes.func.isRequired,
		disabled: PropTypes.bool,
		items: PropTypes.array,
		loading: PropTypes.bool,
		dispatch: PropTypes.func,
	}

	componentDidMount() {
		if (!this.props.items.length) this.props.dispatch(fetchDimensions())
	}

	render() {
		const { loading, items, selectedItem, onChange, disabled } = this.props

		return (
			<div className="loaderDimension">
				<Form.Group>
					<Form.Control
						name="loaderDimension"
						onChange={onChange}
						value={selectedItem}
						disabled={loading || disabled}
						as="select"
						size="lg"
					>
						<option value="">- Select dimension -</option>
						{items.map((item) => (
							<option key={item.key} value={item.key}>
								{item.value}
							</option>
						))}
					</Form.Control>
				</Form.Group>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	loading: state.loaderDimension.loading,
	items: state.loaderDimension.items,
})

export default connect(mapStateToProps)(LoaderDimension)
