import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './style.css'
import Numpad from 'components/Numpad'
import { Form } from 'react-bootstrap'

export default class TyreDimension extends Component {
	static propTypes = {
		onChange: PropTypes.func.isRequired,
		width: PropTypes.any,
		aspectRatio: PropTypes.any,
		diameter: PropTypes.any,
		inchSearch: PropTypes.bool,
		onSubmit: PropTypes.func,
		showNumpad: PropTypes.bool,
	}

	constructor(props) {
		super(props)
		this.state = {
			editing: 0,
			widthLength: 3,
			widthInchLength: 4,
			aspectRatioLength: 2,
			aspectRatioInchLength: 4,
			diameterLength: 2,
			diameterInchLength: 2,
		}

		this.inputs = ['width', 'aspectRatio', 'diameter']
	}


	onSetValue = (value, keyCode) => {
		if (!value && keyCode !== 8) return

		const { editing } = this.state

		const { width, aspectRatio, diameter, inchSearch } = this.props

		const editingName = this.inputs[editing]
		const setInchSearch = value === '.' ? true : inchSearch
		const maxLength = this.state[editingName + (setInchSearch ? 'Inch' : '') + 'Length']

		let currentEdit = ''
		let newValue

		if (keyCode === 8 && editingName)
			newValue = this.props[editingName].slice(0, -1)
		else newValue = this.props[editingName] + value

		if (
			this.props[editingName].length === 0 &&
			newValue.length === 0 &&
			editing !== 0
		)
			currentEdit = editing - 1
		else if (editingName !== this.inputs[this.inputs.length - 1])
			currentEdit = newValue.length >= maxLength ? editing + 1 : editing

		if (currentEdit !== '') {
			this.setState({
				editing: currentEdit,
			})
		}

		if (newValue?.length <= maxLength) {
			this.props.onChange({
				width,
				aspectRatio,
				diameter,
				[editingName]: newValue,
				inchSearch: setInchSearch,
			})
		}
	}

	handleInputClick = (index) => {
		this.setState({ editing: index })
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.width.length > this.props.width.length) {
			this.setState({
				editing: 0,
			})
		}
	}

	render() {
		const { width, aspectRatio, diameter, inchSearch } = this.props

		const editingName = this.inputs[this.state.editing]

		return (
			<>
				<div className="tyreDimension">
					<input
						name="width"
						value={width}
						className={classNames({ active: editingName === 'width' })}
						onChange={this.onSetValue}
						onClick={() => this.handleInputClick(0)}
					/>
					<span>{inchSearch ? 'x' : '/'}</span>
					<input
						name="aspectratio"
						value={aspectRatio}
						className={classNames({ active: editingName === 'aspectRatio' })}
						onChange={this.onSetValue}
						onClick={() => this.handleInputClick(1)}
					/>
					<span>{inchSearch ? 'R' : '-'}</span>
					<input
						name="diameter"
						value={diameter}
						className={classNames({ active: editingName === 'diameter' })}
						onChange={this.onSetValue}
						onClick={() => this.handleInputClick(2)}
					/>
				</div>
				{
					this.props.showNumpad &&
					<Form.Group onSubmit={this.props.onSubmit} >
						<Numpad
							onClick={this.onSetValue}
							onSend={(e) => this.props.onSubmit(e)}
							showStockButton={false}
							allowCharacters={false}
							buttonText="Search"
							hideExtraButton={true}
						/>
					</Form.Group>
				}
			</>
		)
	}
}
