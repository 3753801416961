const initialState = {
	loading: false,
	pickingList: null,
	error: false,
	errorMessage: '',
	pickingGroups: null,
	scannedArticles: {},
	pickingBuildings: null,
}

const FETCH_PICKING_LIST = 'features/Picking/FETCH_PICKING_LIST'
const FETCH_PICKING_LIST_SUCCESS = 'features/Picking/FETCH_PICKING_LIST_SUCCESS'
const FETCH_PICKING_LIST_FAILED = 'features/Picking/FETCH_PICKING_LIST_FAILED'

const START_NEW_PICKING_LIST = 'features/Picking/START_NEW_PICKING_LIST'
const START_NEW_PICKING_LIST_SUCCESS = 'features/Picking/START_NEW_PICKING_LIST_SUCCESS'
const START_NEW_PICKING_LIST_FAILED = 'features/Picking/START_NEW_PICKING_LIST_FAILED'

const SAVE_PICKING = 'features/Picking/SAVE_PICKING'
const SAVE_PICKING_SUCCESS = 'features/Picking/SAVE_PICKING_SUCCESS'
const SAVE_PICKING_FAILED = 'features/Picking/SAVE_PICKING_FAILED'

const FETCH_PICKING_FILTER = 'features/Picking/FETCH_PICKING_FILTER'
const FETCH_PICKING_FILTER_SUCCESS = 'features/Picking/FETCH_PICKING_FILTER_SUCCESS'
const FETCH_PICKING_FILTER_FAILED = 'features/Picking/FETCH_PICKING_FILTER_FAILED'

const PERFORM_PICKINGLIST_ROW_CORRECTION = 'features/Picking/PERFORM_PICKINGLIST_ROW_CORRECTION'
const PERFORM_PICKINGLIST_ROW_CORRECTION_SUCCESS = 'features/Picking/PERFORM_PICKINGLIST_ROW_CORRECTION_SUCCESS'
const PERFORM_PICKINGLIST_ROW_CORRECTION_FAILED = 'features/Picking/PERFORM_PICKINGLIST_ROW_CORRECTION_FAILED'

const PERFORM_BUNDLE_ITEMS = 'features/Picking/PERFORM_BUNDLE_ITEMS'
const PERFORM_BUNDLE_ITEMS_SUCCESS = 'features/Picking/PERFORM_BUNDLE_ITEMS_SUCCESS'
const PERFORM_BUNDLE_ITEMS_FAILED = 'features/Picking/PERFORM_BUNDLE_ITEMS_FAILED'
const PERFORM_PICKINGLIST_ROW_DELETE_CORRECTION = 'features/Picking/PERFORM_PICKINGLIST_ROW_DELETE_CORRECTION'
const PERFORM_PICKINGLIST_ROW_DELETE_CORRECTION_SUCCESS = 'features/Picking/PERFORM_PICKINGLIST_ROW_DELETE_CORRECTION_SUCCESS'
const PERFORM_PICKINGLIST_ROW_DELETE_CORRECTION_FAILED = 'features/Picking/PERFORM_PICKINGLIST_ROW_DELETE_CORRECTION_FAILED'

const PERFORM_PICKINGLIST_ROW_PARTIAL_CORRECTION = 'features/Picking/PERFORM_PICKINGLIST_ROW_PARTIAL_CORRECTION'
const PERFORM_PICKINGLIST_ROW_PARTIAL_CORRECTION_SUCCESS = 'features/Picking/PERFORM_PICKINGLIST_ROW_PARTIAL_CORRECTION_SUCCESS'
const PERFORM_PICKINGLIST_ROW_PARTIAL_CORRECTION_FAILED = 'features/Picking/PERFORM_PICKINGLIST_ROW_PARTIAL_CORRECTION_FAILED'

const UPDATE_PICKING_STATUS = 'features/Picking/UPDATE_PICKING_STATUS'
const SET_SCANNED_ARTICLES = 'features/Picking/SET_SCANNED_ARTICLES'

const RESET_ERROR_MESSAGE = 'features/Picking/RESET_ERROR_MESSAGE'

//Actions
export const performPickingListRowCorrection = (payload) => {
	return {
		types: [
			PERFORM_PICKINGLIST_ROW_CORRECTION,
			PERFORM_PICKINGLIST_ROW_CORRECTION_SUCCESS,
			PERFORM_PICKINGLIST_ROW_CORRECTION_FAILED,
		],
		callApi: {
			endpoint: 'api/PickingRow',
			method: 'PUT',
			data: payload,
		},
	}
}

export const performPickingListRowDeleteCorrection = (payload) => {

	const { ArticleId, StockLocation, NextBusinessDay } = payload
	const data = { ArticleId, StockLocation, NextBusinessDay }

	return {
		types: [
			PERFORM_PICKINGLIST_ROW_DELETE_CORRECTION,
			PERFORM_PICKINGLIST_ROW_DELETE_CORRECTION_SUCCESS,
			PERFORM_PICKINGLIST_ROW_DELETE_CORRECTION_FAILED,
		],
		callApi: {
			endpoint: 'api/PickingRow',
			method: 'DELETE',
			data: data,
		},
	}
}

export const fetchPickingFilter = () => {
	return {
		types: [
			FETCH_PICKING_FILTER,
			FETCH_PICKING_FILTER_SUCCESS,
			FETCH_PICKING_FILTER_FAILED,
		],
		callApi: {
			endpoint: 'api/PickingFilter',
			method: 'GET',
		},
	}
}

export const fetchPickingList = (payload) => {
	return {
		types: [
			FETCH_PICKING_LIST,
			FETCH_PICKING_LIST_SUCCESS,
			FETCH_PICKING_LIST_FAILED,
		],
		callApi: {
			endpoint: 'api/PickingList',
			method: 'GET',
			data: payload,
		},
	}
}

export const startNewPickingList = (payload) => {
	const data = {
		pickingGroupId: payload.pickingGroupId,
		nextBusinessDay: false,
		pickingBuilding: payload.buildingOption,
		pickingFloorOption: payload.floorOption,
		pickingProductGroup: payload.productGroup,
		excludeAccessories: payload.includeAccessories,
	}

	return {
		types: [
			START_NEW_PICKING_LIST,
			START_NEW_PICKING_LIST_SUCCESS,
			START_NEW_PICKING_LIST_FAILED,
		],
		callApi: {
			endpoint: 'api/PickingList',
			method: 'POST',
			data: data,
		},
	}
}

export const completePickingList = (payload) => {
	return {
		types: [SAVE_PICKING, SAVE_PICKING_SUCCESS, SAVE_PICKING_FAILED],
		callApi: {
			endpoint: 'api/PickingList',
			method: 'PUT',
			data: payload,
		},
	}
}

export const performPickingListRowPartialCorrection = (payload) => {
	return {
		types: [
			PERFORM_PICKINGLIST_ROW_PARTIAL_CORRECTION,
			PERFORM_PICKINGLIST_ROW_PARTIAL_CORRECTION_SUCCESS,
			PERFORM_PICKINGLIST_ROW_PARTIAL_CORRECTION_FAILED,
		],
		callApi: {
			endpoint: 'api/PickingRowPartiallyPicked',
			method: 'PUT',
			data: payload,
		},
	}
}

export const updatePickingStatus = (pickingStatus, rowIndex) => ({
	type: UPDATE_PICKING_STATUS,
	payload: { pickingStatus, rowIndex },
})

export const bundleItems = (payload) => ({
	types: [PERFORM_BUNDLE_ITEMS, PERFORM_BUNDLE_ITEMS_SUCCESS, PERFORM_BUNDLE_ITEMS_FAILED],
	callApi: {
		endpoint: 'api/BundlePicking',
		method: 'POST',
		data: payload,
	},
})

export const resetErrorMessage = () => {
	return {
		type: RESET_ERROR_MESSAGE,
	}
}

export const setScannedArticles = (payload) => {
	return {
		type: SET_SCANNED_ARTICLES,
		payload,
	}
}

const performLogicToUpdateScannedArticles = (
	prevScannedArticles,
	newPickingList,
) => {
	const newRows = newPickingList.rows

	const updatedScannedArticles = { ...prevScannedArticles }

	newRows.forEach((newRow) => {
		// Check if the row matches anything in the previous scanned articles
		const matchingLocation = Object.keys(updatedScannedArticles).find(
			(location) => location === newRow.stockLocation,
		)

		if (matchingLocation) {
			// Check if the article in the new row matches anything in the previous scanned articles
			const matchingArticle = Object.keys(
				updatedScannedArticles[matchingLocation],
			).find((ean) => ean === newRow.ean)

			if (matchingArticle) {
				// Update the quantity in the scanned articles based on the new row's quantity
				updatedScannedArticles[matchingLocation][matchingArticle] =
					newRow.quantity
			}
		}
	})

	return updatedScannedArticles
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_PICKING_FILTER:
			return {
				...state,
				loading: true,
				pickingGroups: null,
				pickingBuildings: null,
			}
		case FETCH_PICKING_FILTER_SUCCESS:
			return {
				...state,
				loading: false,
				pickingGroups: action.data.pickingGroupList,
				pickingBuildings: action.data.buildingList,
			}
		case FETCH_PICKING_FILTER_FAILED:
			return {
				...state,
				loading: false,
				pickingGroups: null,
				pickingBuildings: null,
			}
		case FETCH_PICKING_LIST:
			return {
				...state,
				loading: true,
			}
		case FETCH_PICKING_LIST_SUCCESS:
			const newPickingList = action.data
			const currentSequenceNumber = state.pickingList?.sequenceNumber
			const currentPickingGroupDisplayText =
				state.pickingList?.pickingGroupDisplayText

			if (
				currentSequenceNumber === newPickingList.sequenceNumber &&
				newPickingList.pickingGroupDisplayText ===
				currentPickingGroupDisplayText
			) {
				const updatedScannedArticles = performLogicToUpdateScannedArticles(
					state.scannedArticles,
					newPickingList,
				)

				return {
					...state,
					loading: false,
					pickingList: newPickingList,
					scannedArticles: updatedScannedArticles,
				}
			} else {
				return {
					...state,
					loading: false,
					pickingList: newPickingList,
					scannedArticles: {},
				}
			}
		case FETCH_PICKING_LIST_FAILED:
			return {
				...state,
				loading: false,
				pickingList: null,
				errorMessage: action.error.message,
			}
		case START_NEW_PICKING_LIST:
			return {
				...state,
				loading: true,
			}
		case START_NEW_PICKING_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				errorMessage: '',
			}
		case START_NEW_PICKING_LIST_FAILED:
			return {
				...state,
				loading: false,
				error: true,
				errorMessage: action.error.message,
			}
		case SAVE_PICKING:
			return {
				...state,
				loading: true,
			}
		case SAVE_PICKING_SUCCESS:
			return {
				...state,
				loading: false,
				pickingList: null,
			}
		case SAVE_PICKING_FAILED:
			return {
				...state,
				loading: false,
				error: true,
				errorMessage: action.error.message,
			}
		case SET_SCANNED_ARTICLES:
			return {
				...state,
				scannedArticles: action.payload,
			}
		case PERFORM_PICKINGLIST_ROW_CORRECTION:
			return {
				...state,
				loading: true,
				error: false,
				errorMessage: '',
			}
		case PERFORM_PICKINGLIST_ROW_CORRECTION_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				errorMessage: '',
			}

		case PERFORM_PICKINGLIST_ROW_CORRECTION_FAILED:
			return {
				...state,
				loading: false,
				error: true,
				errorMessage: action.error.message,
			}
		case PERFORM_PICKINGLIST_ROW_DELETE_CORRECTION:
			return {
				...state,
				loading: true,
				error: false,
				errorMessage: '',
			}
		case PERFORM_PICKINGLIST_ROW_DELETE_CORRECTION_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				errorMessage: '',
			}
		case PERFORM_PICKINGLIST_ROW_DELETE_CORRECTION_FAILED:
			return {
				...state,
				loading: false,
				error: true,
				errorMessage: action.error.message,
			}
		case PERFORM_PICKINGLIST_ROW_PARTIAL_CORRECTION:
			return {
				...state,
				loading: true,
				error: false,
				errorMessage: '',
			}
		case PERFORM_PICKINGLIST_ROW_PARTIAL_CORRECTION_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				errorMessage: '',
			}
		case PERFORM_PICKINGLIST_ROW_PARTIAL_CORRECTION_FAILED:
			return {
				...state,
				loading: false,
				error: true,
				errorMessage: action.error.message,
			}
		case PERFORM_BUNDLE_ITEMS:
			return {
				...state,
				loading: true,
				error: false,
				errorMessage: '',
			}
		case PERFORM_BUNDLE_ITEMS_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				pickingList: action.data,
				errorMessage: '',
			}
		case PERFORM_BUNDLE_ITEMS_FAILED:
			return {
				...state,
				loading: false,
				error: true,
				errorMessage: 'Unable to bundle items',
			}
		case UPDATE_PICKING_STATUS: {
			const { rowIndex, pickingStatus } = action.payload
			const updatedRows = state.pickingList.rows.map((row, index) => {
				if (rowIndex === index) {
					return {
						...row,
						pickingStatus,
					}
				}
				return row
			})

			return {
				...state,
				pickingList: {
					...state.pickingList,
					rows: updatedRows,
				},
			}
		}
		case RESET_ERROR_MESSAGE:
			return {
				...state,
				error: false,
				errorMessage: '',
			}

		default:
			return state
	}
}
