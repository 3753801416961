import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createNewStockTaking } from 'features/StockTaking/duck'
import { useNavigate } from 'react-router-dom'
import { usePrevious } from 'lib/utils'

const useStockTakingCreator = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const loadingStockTaking = useSelector((state) => state.stockTaking.loading)
	const sequenceNumber = useSelector(
		(state) => state.stockTaking.createdSequenceNumber,
	)
	const prevSequenceNumber = usePrevious(sequenceNumber)
	const [createdSequenceNumber, setCreatedSequenceNumber] = useState(null)

	const createStockTakingByLocations = (stockLocations) => {
		dispatch(
			createNewStockTaking({
				stockLocations,
			}),
		)
	}

	const createStockTakingByArticleIds = (articleIds, stockLocation) => {
		dispatch(
			createNewStockTaking({
				articleIds,
				stockLocation,
			}),
		)
	}

	useEffect(() => {
		if (prevSequenceNumber === null && sequenceNumber !== null) {
			setCreatedSequenceNumber(sequenceNumber)
			navigate(`/stocktaking/${sequenceNumber}`)
		}
	}, [prevSequenceNumber, sequenceNumber, navigate])

	return {
		loadingStockTaking,
		createdSequenceNumber,
		createStockTakingByLocations,
		createStockTakingByArticleIds,
	}
}

export default useStockTakingCreator
