const initialState = {
	printers: [],
	selectedPrinter: '',
	showPrinting: false,
}

const SET_ACTIVE_PRINTERS = 'features/printing/SET_ACTIVE_PRINTERS'
const TOGGLE_SHOW_PRINTING = 'features/printing/TOGGLE_SHOW_PRINTING'
const ADD_PRINTER = 'features/printing/ADD_PRINTER'
const REMOVE_PRINTER = 'features/printing/REMOVE_PRINTER'
const SET_SELECTED_PRINTER = 'features/printing/SET_SELECTED_PRINTER'

export const setActivePrinters = (printers) => {
	return {
		type: SET_ACTIVE_PRINTERS,
		data: printers,
	}
}

export const setSelectedPrinter = (printer) => {
	return {
		type: SET_SELECTED_PRINTER,
		data: printer,
	}
}

export const toggleShowPrinting = () => {
	return {
		type: TOGGLE_SHOW_PRINTING,
	}
}

export const addPrinter = (printer) => {
	return {
		type: ADD_PRINTER,
		data: printer,
	}
}

export const removePrinter = (printer) => {
	return {
		type: REMOVE_PRINTER,
		data: printer,
	}
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case SET_ACTIVE_PRINTERS:
			return {
				...state,
				printers: action.data,
			}

		case ADD_PRINTER:
			return {
				...state,
				printers: [...state.printers, action.data],
			}

		case REMOVE_PRINTER:
			return {
				...state,
				printers: state.printers.filter(
					(printer) => printer.connectionId !== action.data.connectionId,
				),
			}

		case SET_SELECTED_PRINTER:
			return {
				...state,
				selectedPrinter: action.data,
			}

		case TOGGLE_SHOW_PRINTING:
			return {
				...state,
				showPrinting: !state.showPrinting,
			}

		default:
			return state
	}
}
