import { configureStore } from '@reduxjs/toolkit'
import fetchMiddleware from './fetchMiddleware'
import unauthorizedMiddleware from './unauthorizedMiddleware'
import { apiBase } from 'api/apiBase'
import app from 'features/App/duck'
import login from 'features/Login/duck'
import stockLocationSearch from 'features/StockLocationSearch/duck'
import stockCorrection from 'features/StockCorrection/duck'
import moveArticle from 'features/MoveArticle/duck'
import articleStockLocationAdd from 'features/ArticleStockLocationAdd/duck'
import articleSearch from 'features/ArticleSearch/duck'
import article from 'features/Article/duck'
import articleStockLocationList from 'features/ArticleStockLocationList/duck'
import messageBox from 'features/MessageBox/duck'
import truckDimension from 'features/TruckDimension/duck'
import loaderDimension from 'features/LoaderDimension/duck'
import truckRimDimension from 'features/TruckRimDimension/duck'
import inboundOrder from 'features/InboundOrder/duck'
import inboundDeliverys from 'features/InboundDelivery/duck'
import barCodeScanner from 'features/BarCodeScanner/duck'
import pickingList from 'features/Picking/duck'
import stockTaking from 'features/StockTaking/duck'
import rollingInventory from 'features/RollingInventory/duck'
import printing from 'features/Printing/duck'
import warehouses from 'features/Warehouses/duck'
import stockLocationMove from 'features/StockLocationMove/duck'


export const store = configureStore({
	reducer: {
		app,
		login,
		stockLocationSearch,
		stockCorrection,
		moveArticle,
		articleStockLocationAdd,
		articleSearch,
		article,
		articleStockLocationList,
		messageBox,
		truckDimension,
		loaderDimension,
		truckRimDimension,
		inboundOrder,
		inboundDeliverys,
		barCodeScanner,
		pickingList,
		stockTaking,
		rollingInventory,
		printing,
		warehouses,
		stockLocationMove,
		[apiBase.reducerPath]: apiBase.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			thunk: {
				// extraArgument: {
				// 	cookies: context?.req?.headers?.cookie
				// },
			},
		})
			.concat([
				fetchMiddleware,
				unauthorizedMiddleware,
				apiBase.middleware
			]),
	devTools: process.env.NODE_ENV !== 'production',
})