const initialState = {
	loading: false,
	locations: null,
	inventory: null,
	sortType: '1',
	selectedWarehouse: '',
	selectedHall: '',
	selectedAisle: '',
	selectedPlace: '',
	selectedHeight: '',
	userWarehouseId: null,
	warehouseFilterRef: null
}

const FETCH_LOCATIONS = 'FETCH_LOCATIONS'
const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS'
const FETCH_LOCATIONS_FAILED = 'FETCH_LOCATIONS_FAILED'

const SET_VALUE = 'features/RollingInventory/SET_VALUE'

const FETCH_FILTER = 'FETCH_FILTER'
const FETCH_FILTER_SUCCESS = 'FETCH_FILTER_SUCCESS'
const FETCH_FILTER_FAILED = 'FETCH_FILTER_FAILED'

export const fetchLocations = (payload) => ({
	types: [FETCH_LOCATIONS, FETCH_LOCATIONS_SUCCESS, FETCH_LOCATIONS_FAILED],
	callApi: {
		endpoint: 'api/StockLocationReviewList',
		method: 'GET',
		data: payload,
	},
})

export const setValue = (name, value) => ({
	type: SET_VALUE,
	name,
	value,
})

export const fetchFilter = () => ({
	types: [FETCH_FILTER, FETCH_FILTER_SUCCESS, FETCH_FILTER_FAILED],
	callApi: {
		endpoint: 'api/StockLocationReviewFilter',
		method: 'GET',
	},
})

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_LOCATIONS:
			return {
				...state,
				loading: true,
				locations: null,
			}
		case FETCH_LOCATIONS_SUCCESS:
			return {
				...state,
				loading: false,
				locations: action.data,
			}
		case FETCH_LOCATIONS_FAILED:
			return {
				...state,
				loading: false,
			}
		case FETCH_FILTER:
			return {
				...state,
				loading: true,
				warehouseFilterRef: null,
			}
		case FETCH_FILTER_SUCCESS:
			return {
				...state,
				loading: false,
				warehouseFilterRef: action.data,
			}
		case FETCH_FILTER_FAILED:
			return {
				...state,
				loading: false,
			}
		case SET_VALUE:
			return {
				...state,
				[action.name]: action.value,
			}
		default:
			return state
	}
}

export default reducer