import './style.css'

import React from 'react'
import propTypes from 'prop-types'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'

const RollingInventorySearchForm = ({
	onSubmit,
	onChange,
	selectedWarehouse,
	selectedHall,
	selectedAisle,
	selectedPlace,
	selectedHeight,
	userWarehouseId,
	sortType,
	warehouseFilterRef
}) => {
	const onChangeHandler = (e) => {
		onChange(e.target.name, e.target.value)
	}

	const onSortTypeChange = (e) => {
		onChange('sortType', e.target.value)
	}

	return (
		<Container className="rollingInventorySearchForm">
			<Row className="justify-content-center">
				<Col xs={12} sm={12}>
					<Card>
						<Card.Header>Rolling Inventory Search</Card.Header>
						<Card.Body>
							<Form onSubmit={onSubmit}>
								<Form.Group controlId='SortType'>
									<Form.Control
										name="sortType"
										onChange={onSortTypeChange}
										value={sortType}
										as="select"
										size="lg"
									>
										<option value={1}>Last reviewed</option>
										<option value={2}>Location (A-Z)</option>
									</Form.Control>
								</Form.Group>
								<Form.Group controlId='SelectedWarehouse'>
									<Form.Control
										name="selectedWarehouse"
										onChange={onChangeHandler}
										value={selectedWarehouse}
										as="select"
										size="lg"
										disabled={userWarehouseId}
									>
										<option value={''}>All warehouses</option>
										{warehouseFilterRef?.warehouseList.map((warehouse) => (
											<option key={warehouse.id} value={warehouse.id}>
												{warehouse.name}
											</option>
										))}
									</Form.Control>
								</Form.Group>
								<Form.Group controlId='SelectedHall'>
									<Form.Control
										name="selectedHall"
										onChange={onChangeHandler}
										value={selectedHall}
										as="select"
										size="lg"
									>
										<option value={''}>All halls</option>
										{warehouseFilterRef?.hallList?.map((hallNumber) => (
											<option key={hallNumber} value={hallNumber}>
												Hall Nr. {hallNumber}
											</option>
										))}
									</Form.Control>
								</Form.Group>
								<Form.Group controlId='SelectedAisle'>
									<Form.Control
										name="selectedAisle"
										onChange={onChangeHandler}
										value={selectedAisle}
										as="select"
										size="lg"
									>
										<option value={''}>All aisles</option>
										{warehouseFilterRef?.aisleList?.map((aisleNumber) => (
											<option key={aisleNumber} value={aisleNumber}>
												Aisle Nr. {aisleNumber}
											</option>
										))}
									</Form.Control>
								</Form.Group>
								<Form.Group controlId='SelectedPlace'>
									<Form.Control
										name="selectedPlace"
										onChange={onChangeHandler}
										value={selectedPlace}
										as="select"
										size="lg"
									>
										<option value={''}>All places</option>
										{warehouseFilterRef?.placeList?.map((aislePlaceNumber) => (
											<option key={aislePlaceNumber} value={aislePlaceNumber}>
												Place Nr. {aislePlaceNumber}
											</option>
										))}
									</Form.Control>
								</Form.Group>
								<Form.Group controlId='SelectedHeight'>
									<Form.Control
										name="selectedHeight"
										onChange={onChangeHandler}
										value={selectedHeight}
										as="select"
										size="lg"
									>
										<option value={''}>All heights</option>
										{warehouseFilterRef?.heightList?.map((heightNumber) => (
											<option key={heightNumber} value={heightNumber}>
												Height Nr. {heightNumber}
											</option>
										))}
									</Form.Control>
								</Form.Group>
							</Form>
						</Card.Body>
					</Card>

					<Form.Group>
						<ButtonGroup size="lg">
							<Button variant="primary" type="submit" onClick={onSubmit}>
								Submit
							</Button>
						</ButtonGroup>
					</Form.Group>
				</Col>
			</Row>
		</Container>
	)
}

RollingInventorySearchForm.propTypes = {
	onSubmit: propTypes.func,
	onChange: propTypes.func,
	selectedWarehouse: propTypes.string,
	userWarehouseId: propTypes.string,
	sortType: propTypes.string,
	selectedHall: propTypes.string,
	selectedAisle: propTypes.string,
	selectedPlace: propTypes.string,
	selectedHeight: propTypes.string,
	warehouseFilterRef: propTypes.object,
}

export default RollingInventorySearchForm