import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import ArticleTitle from 'components/ArticleTitle'
import StockChangesForm from 'components/StockChangesForm'
import LoadingOverlay from 'components/LoadingOverlay'
import { getPrefixIfUserWarehouse } from 'lib/utils'
import { useNavigate, useParams } from 'react-router'

import {
	postStockLocation,
	fetchArticle,
	setValue,
	fetchStock,
	validateStockLocation,
	resetValidateStockLocation,
} from './duck'

import { showMessage } from 'features/MessageBox/duck'

const MoveArticle = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { articleId, stockLocation, dotYear } = useParams()

	const article = useSelector(state => state.moveArticle.article)
	const moveQuantity = useSelector(state => state.moveArticle.moveQuantity)
	const newLocation = useSelector(state => state.moveArticle.newLocation)
	const stockInfo = useSelector(state => state.moveArticle.stockInfo)
	const loading = useSelector(state => state.moveArticle.loading)
	const completed = useSelector(state => state.moveArticle.completed)
	const stockLocationValid = useSelector(state => state.moveArticle.stockLocationValid)
	const user = useSelector(state => state.login.user)
	const error = useSelector(state => state.moveArticle.error)

	const onGoBackClicked = () => {
		navigate(-1)
	}

	const onChange = (name, value) => {
		dispatch(setValue(name, value))
	}

	const onSubmit = (e) => {
		if (e)
			e.preventDefault()

		let toLocation = getPrefixIfUserWarehouse(user, newLocation)

		if (!moveQuantity.trim().length || !newLocation.trim().length)
			return


		if (moveQuantity > stockInfo.quantityInStock) {
			showInvalidQuantity()
			return
		}

		if (stockLocationValid === null) {
			validateStockLocationFn(toLocation)
			return
		} else if (stockLocationValid === false) {
			showInvalidStockLocation()
			return
		}

		dispatch(postStockLocation(article.articleId, moveQuantity, stockLocation, toLocation, dotYear))
	}

	const fetchArticleStock = () => {
		dispatch(fetchStock(articleId, stockLocation, dotYear))
	}

	const validateStockLocationFn = useCallback((toLocation) => {
		dispatch(validateStockLocation(stockLocation, toLocation))
	}, [dispatch, stockLocation])

	const showInvalidStockLocation = () => {
		dispatch(resetValidateStockLocation())
		dispatch(showMessage('Invalid location', 'Unable to move quantity to another warehouse!'))
	}

	const showInvalidQuantity = () => {
		dispatch(showMessage('Invalid quantity', 'Unable to move more quantity than available!'))
	}

	const showError = () => {
		dispatch(showMessage('Error', 'Failed to create relocation'))
	}

	useEffect(() => {
		dispatch(fetchArticle(articleId))
		dispatch(fetchStock(articleId, stockLocation, dotYear))
	}, [dispatch, articleId, stockLocation, dotYear])

	useEffect(() => {
		if (!completed && completed !== undefined) fetchArticleStock()

		if (stockLocationValid !== null && stockLocationValid !== undefined && stockLocationValid !== false) onSubmit()

		if (error) showError()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [completed, stockLocationValid, error])

	const loadingSpinner = loading ? <LoadingOverlay /> : ''

	return (
		<Container>
			<Row className="justify-content-center">
				<Col xs={12} sm={12}>
					<Row className="justify-content-center">
						<Card style={{ width: '32rem' }}>
							<Card.Header>Move Article</Card.Header>
							<Card.Body>
								{article && <ArticleTitle item={article} dotYear={dotYear} />}
							</Card.Body>
						</Card>
					</Row>
				</Col>
			</Row>

			<Row className="justify-content-center">
				<StockChangesForm
					onSubmit={onSubmit}
					onBack={onGoBackClicked}
					isStockCorrection={false}
					onChange={onChange}
					currentQuantity={stockInfo?.quantityInStock || 0}
					currentlyInPicking={stockInfo?.quantityReserved || 0}
					moveQuantity={moveQuantity}
					newLocation={newLocation}
				/>
			</Row>

			{loadingSpinner}
		</Container>
	)
}

export default MoveArticle
